import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {TextField, Button, MenuItem} from '@material-ui/core';

import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import DatePicker from '../Components/DatePicker';
import {toDateString, fromDateString} from '../lib/helpers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faGift,
	faGraduationCap,
	faLandmark,
} from '@fortawesome/free-solid-svg-icons';
import {checkToken} from '../Context/actions';

const VideoForm = ({user, client, video, editMode, videoForm, setActive}) => {
	// console.log(video, client);
	const videoLength = video && video.video.length > 0;
	const [editVideo, setEditVideo] = React.useState(videoLength);
	const [pillars, setPillars] = React.useState();
	const [incentives, setIncentives] = React.useState();
	const {control, handleSubmit} = videoForm;

	async function getPillars() {
		const response = await API.graphql({
			query: queries.listPillars,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getIncentives() {
		const response = await API.graphql({
			query: queries.listIncentives,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	React.useEffect(() => {
		const pillars = getPillars();
		pillars
			.then((res) => setPillars(res.data.listPillars.items))
			.catch((err) => checkToken());
		const incentives = getIncentives();
		incentives
			.then((res) => setIncentives(res.data.listIncentives.items))
			.catch((err) => checkToken());
	}, []);

	async function createVideo(newVideo) {
		const response = await API.graphql({
			query: mutations.createVideo,
			variables: {input: newVideo},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			videoForm.reset();
			setActive('client');
		}

		return response;
	}
	async function updateVideo(existingVideo) {
		console.log(existingVideo);
		const response = await API.graphql({
			query: mutations.updateVideo,
			variables: {input: existingVideo},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			videoForm.reset();
			setActive('client');
		}

		return response;
	}

	const onSubmit = (data) => {
		let videoValues = data;
		videoValues.clientID = client.id;

		videoValues.date = toDateString(
			data.videomonth,
			data.videoday,
			data.videoyear
		);
		let parsed = Object.keys(videoValues)
			.filter((k) => videoValues[k] != null)
			.reduce((a, k) => ({...a, [k]: videoValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;
		delete parsed.videomonth;
		delete parsed.videoday;
		delete parsed.videoyear;

		console.log(parsed);
		createVideo(parsed);
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DatePicker
					form={videoForm}
					groupLabel="Completion Date"
					initialValue={
						(video && fromDateString(video.date)) || {
							month: 'January',
						}
					}
					nameObj={{
						month: 'videomonth',
						day: 'videoday',
						year: 'videoyear',
					}}
				/>
				<DetailItem>
					<FieldWrapper>
						<FontAwesomeIcon icon={faGraduationCap} size="lg" />
						<Controller
							name="video_id"
							control={control}
							render={({field}) => (
								<TextField
									label="Video ID"
									variant="outlined"
									defaultValue={
										(video && video.video_id) || ''
									}
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faLandmark} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="pillar-field"
							name="pillarID"
							label="Pillar"
							defaultValue={(video && video.pillarID) || ''}
							variant="outlined">
							{pillars &&
								pillars.map((pillar, key) => (
									<MenuItem key={key} value={pillar.id}>
										{pillar.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faGift} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="incentive-field"
							name="incentiveID"
							label="Incentive"
							defaultValue={(video && video.incentiveID) || ''}
							variant="outlined">
							{incentives &&
								incentives.map((incentive, key) => (
									<MenuItem key={key} value={incentive.id}>
										{incentive.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default VideoForm;
