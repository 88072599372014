/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Notify from '../Components/Notify';

import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import PillarsForm from '../Forms/PillarsForm';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const PillarsDashboard = () => {
	const [pillars, setPillars] = React.useState();
	const [selectedPillar, setSelectedPillar] = React.useState();
	const [refreshPillars, setRefreshPillars] = React.useState(false);
	const [activeView, setActiveView] = React.useState('pillars');
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const pillarsForm = useForm({
		defaultValues: selectedPillar,
	});

	async function getPillars() {
		const response = await API.graphql({
			query: queries.listPillars,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	function deletePillar(pillarId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: pillarId,
			};
			const response = await API.graphql({
				query: mutations.deletePillar,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (response.data.deletePillar.id === pillarId) {
				setShowNotify(false);
				setRefreshPillars(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		const pillarData = getPillars();
		pillarData
			.then((res) => setPillars(res.data.listPillars.items))
			.catch((err) => checkToken());
		console.log(pillars);
		setRefreshPillars(false);
	}, [refreshPillars]);

	return !pillars ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'pillars' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>Pillars</h1>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) => setActiveView('addPillar')}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add Pillar</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>

					<ItemList>
						<ItemListTitles>
							<div className="item">
								<p>Name</p>
							</div>
							<div className="item">
								<p>Description</p>
							</div>
							<div className="item">
								<p>Actions</p>
							</div>
						</ItemListTitles>
						{pillars.map((pillar, key) => (
							<ItemRow key={key}>
								<div className="item">
									<p>{pillar.name}</p>
								</div>
								<div className="item">
									<p>{pillar.description}</p>
								</div>

								<div className="action">
									<button
										type="button"
										title="Edit Pillar"
										onClick={() => {
											setSelectedPillar(pillar);
											setActiveView('editPillar');
										}}>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										type="button"
										title="Delete Pillar"
										onClick={() => {
											deletePillar(pillar.id, {
												message:
													'Are you sure you want to delete this pillar?',
												confirm: 'Delete',
												cancel: 'Cancel',
												action: {},
											});
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</button>
								</div>
							</ItemRow>
						))}
					</ItemList>
				</>
			) : null}
			{activeView === 'addPillar' ? (
				<>
					<ColumnHeader>
						<h1>Add Pillar</h1>
						<button
							type="button"
							onClick={(e) => {
								pillarsForm.reset();
								setActiveView('pillars');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<PillarsForm
						setActiveView={setActiveView}
						pillarsForm={pillarsForm}
						setRefreshPillars={() => {
							setRefreshPillars(true);
						}}
					/>
				</>
			) : null}
			{activeView === 'editPillar' ? (
				<>
					<ColumnHeader>
						<h1>Edit Pillar</h1>
						<button
							type="button"
							onClick={(e) => {
								pillarsForm.reset();
								setActiveView('pillars');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<PillarsForm
						setActiveView={setActiveView}
						editMode={true}
						pillar={selectedPillar}
						pillarsForm={pillarsForm}
						setRefreshPillars={() => {
							setRefreshPillars(true);
						}}
					/>
				</>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default PillarsDashboard;
