/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!, $classesLimit: Int) {
    getClient(id: $id) {
      id
      type
      firstName
      lastName
      birthdate
      address
      city
      state
      zip
      email
      phone
      maritalStatus
      preferredLanguage
      ethnicity
      education
      wicStatus
      iris
      intakeStatus
      referringAgency
      classes(limit: $classesLimit) {
        items {
          id
          clientID
          name
          description
          completion_date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          clientID
          video_id
          date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      layette
      newLayette {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      helps {
        items {
          id
          clientID
          date
          helpName
          helpID
          createdAt
          updatedAt
        }
        nextToken
      }
      children {
        items {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        nextToken
      }
      specialServices {
        items {
          id
          clientID
          date
          serviceName
          typeID
          createdAt
          updatedAt
        }
        nextToken
      }
      storeVisits {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      notes {
        items {
          id
          clientID
          clientFirstname
          clientLastname
          type
          createdByUser
          createdByUserName
          date
          createdAt
          note
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        firstName
        lastName
        birthdate
        address
        city
        state
        zip
        email
        phone
        maritalStatus
        preferredLanguage
        ethnicity
        education
        wicStatus
        iris
        intakeStatus
        referringAgency
        classes {
          nextToken
        }
        videos {
          nextToken
        }
        layette
        newLayette {
          nextToken
        }
        helps {
          nextToken
        }
        children {
          nextToken
        }
        specialServices {
          nextToken
        }
        storeVisits {
          nextToken
        }
        description
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClientNote = /* GraphQL */ `
  query GetClientNote($id: ID!) {
    getClientNote(id: $id) {
      id
      clientID
      clientFirstname
      clientLastname
      type
      createdByUser
      createdByUserName
      date
      createdAt
      note
      updatedAt
    }
  }
`;
export const listClientNotes = /* GraphQL */ `
  query ListClientNotes(
    $filter: ModelClientNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        clientFirstname
        clientLastname
        type
        createdByUser
        createdByUserName
        date
        createdAt
        note
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChild = /* GraphQL */ `
  query GetChild($id: ID!) {
    getChild(id: $id) {
      id
      clientID
      pregnancy
      dueDate
      firstName
      lastName
      birthdate
      gender
      vaccination
      feedingType
      daycare {
        id
        date
        child {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChilds = /* GraphQL */ `
  query ListChilds(
    $filter: ModelChildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChilds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        pregnancy
        dueDate
        firstName
        lastName
        birthdate
        gender
        vaccination
        feedingType
        daycare {
          id
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDaycareVisit = /* GraphQL */ `
  query GetDaycareVisit($id: ID!) {
    getDaycareVisit(id: $id) {
      id
      date
      child {
        id
        clientID
        pregnancy
        dueDate
        firstName
        lastName
        birthdate
        gender
        vaccination
        feedingType
        daycare {
          id
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDaycareVisits = /* GraphQL */ `
  query ListDaycareVisits(
    $filter: ModelDaycareVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDaycareVisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        child {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferringAgency = /* GraphQL */ `
  query GetReferringAgency($id: ID!) {
    getReferringAgency(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listReferringAgencys = /* GraphQL */ `
  query ListReferringAgencys(
    $filter: ModelReferringAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferringAgencys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPillar = /* GraphQL */ `
  query GetPillar($id: ID!) {
    getPillar(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listPillars = /* GraphQL */ `
  query ListPillars(
    $filter: ModelPillarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPillars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      clientID
      name
      description
      completion_date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClasss = /* GraphQL */ `
  query ListClasss(
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        name
        description
        completion_date
        pillarID
        pillar {
          id
          name
          description
          createdAt
          updatedAt
        }
        incentiveID
        incentive {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      clientID
      video_id
      date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        video_id
        date
        pillarID
        pillar {
          id
          name
          description
          createdAt
          updatedAt
        }
        incentiveID
        incentive {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelp = /* GraphQL */ `
  query GetHelp($id: ID!) {
    getHelp(id: $id) {
      id
      clientID
      date
      helpName
      helpID
      help {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHelps = /* GraphQL */ `
  query ListHelps(
    $filter: ModelHelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        date
        helpName
        helpID
        help {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelpType = /* GraphQL */ `
  query GetHelpType($id: ID!) {
    getHelpType(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listHelpTypes = /* GraphQL */ `
  query ListHelpTypes(
    $filter: ModelHelpTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIncentive = /* GraphQL */ `
  query GetIncentive($id: ID!) {
    getIncentive(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listIncentives = /* GraphQL */ `
  query ListIncentives(
    $filter: ModelIncentiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncentives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialService = /* GraphQL */ `
  query GetSpecialService($id: ID!) {
    getSpecialService(id: $id) {
      id
      clientID
      date
      serviceName
      typeID
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialServices = /* GraphQL */ `
  query ListSpecialServices(
    $filter: ModelSpecialServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        date
        serviceName
        typeID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialServiceType = /* GraphQL */ `
  query GetSpecialServiceType($id: ID!) {
    getSpecialServiceType(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialServiceTypes = /* GraphQL */ `
  query ListSpecialServiceTypes(
    $filter: ModelSpecialServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialServiceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLayette = /* GraphQL */ `
  query GetLayette($id: ID!) {
    getLayette(id: $id) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const listLayettes = /* GraphQL */ `
  query ListLayettes(
    $filter: ModelLayetteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLayettes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStoreVisit = /* GraphQL */ `
  query GetStoreVisit($id: ID!) {
    getStoreVisit(id: $id) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const listStoreVisits = /* GraphQL */ `
  query ListStoreVisits(
    $filter: ModelStoreVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreVisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const clientsByDate = /* GraphQL */ `
  query ClientsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        firstName
        lastName
        birthdate
        address
        city
        state
        zip
        email
        phone
        maritalStatus
        ethnicity
        education
        wicStatus
        iris
        intakeStatus
        referringAgency
        classes {
          nextToken
        }
        videos {
          nextToken
        }
        layette
        newLayette {
          nextToken
        }
        helps {
          nextToken
        }
        children {
          nextToken
        }
        specialServices {
          nextToken
        }
        storeVisits {
          nextToken
        }
        description
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notesByDate = /* GraphQL */ `
  query NotesByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        clientFirstname
        clientLastname
        type
        createdByUser
        createdByUserName
        date
        createdAt
        note
        updatedAt
      }
      nextToken
    }
  }
`;
export const notesByUser = /* GraphQL */ `
  query NotesByUser(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByUser(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        clientFirstname
        clientLastname
        type
        createdByUser
        createdByUserName
        date
        createdAt
        note
        updatedAt
      }
      nextToken
    }
  }
`;

//Counts
export const countClients = /* GraphQL */ `
  query CountClients( 
    $filter: ModelClientFilterInput
    $limit: Int
  ) {
    listClients(filter: $filter, limit: $limit) {
      items {
        zip
      }
    }
  }
`;
export const countChildren = /* GraphQL */ `
  query CountChildren(
    $filter: ModelChildFilterInput
    $limit: Int
  ) {
    listChilds(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const countLayettes = /* GraphQL */ `
  query CountLayettes(
    $filter: ModelLayetteFilterInput
    $limit: Int
  ) {
    listLayettes(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const countHelps = /* GraphQL */ `
  query CountHelps(
    $filter: ModelHelpFilterInput
    $limit: Int
  ) {
    listHelps(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const countClasses = /* GraphQL */ `
  query CountClasses(
    $filter: ModelClassFilterInput
    $limit: Int
  ) {
    listClasss(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const countVideos = /* GraphQL */ `
  query CountVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
  ) {
    listVideos(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const countStoreVisits = /* GraphQL */ `
  query CountStoreVisits(
    $filter: ModelStoreVisitFilterInput
    $limit: Int
  ) {
    listStoreVisits(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;