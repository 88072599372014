import {Auth} from 'aws-amplify';

export const updateUserState = (dispatch, state) => {
	const user = state.data;
	let exp, accessToken, idToken, groups;
	if (user && user.signInUserSession) {
		exp = user.signInUserSession.accessToken.payload.exp;
		accessToken = user.signInUserSession.accessToken.jwtToken;
		idToken = user.signInUserSession.idToken.jwtToken;
		groups = user.signInUserSession.idToken.payload['cognito:groups'];
	}
	const data = {exp, user, accessToken, idToken, groups};
	localStorage.setItem('currentUser', JSON.stringify(user));
	localStorage.setItem('accessToken', JSON.stringify(accessToken));
	localStorage.setItem('idToken', JSON.stringify(idToken));
	localStorage.setItem('groups', JSON.stringify(groups));
	dispatch({type: state.auth, payload: {data}});
	return state.auth;
};

export const handleSignout = async (dispatch) => {
	try {
		console.log('signed out');
		await Auth.signOut();
		localStorage.removeItem('user');
		localStorage.removeItem('adminUsersCache');
		dispatch({type: 'RESET_USER_DATA'});
	} catch (error) {
		console.error('Error signing out user ', error);
	}
};

export const checkToken = async (exp, dispatch) => {
	try {
		const cognitoUser = await Auth.currentAuthenticatedUser();
		const currentSession = await Auth.currentSession();
		cognitoUser.refreshSession(
			currentSession.refreshToken,
			(err, session) => {
				// console.log('session', err, session);
				const {idToken, refreshToken, accessToken} = session;
				// do whatever you want to do now :)
			}
		);
	} catch (e) {
		console.log('Unable to refresh Token', e);
	}
};
