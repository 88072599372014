import * as React from 'react';
import API from '@aws-amplify/api';
import * as mutations from '../graphql/mutations';
import ActivitiesFilters from '../Layout/ActivitiesFilters';
import {
	DisplayItem,
	ActivitiesDashboard,
	ActivityDeleteButton,
	ColumnHeader,
	ClientActionButton,
} from '../Global/StyledComponents';
import {formatDate} from '../lib/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faGraduationCap,
	faVideo,
	faHandshake,
	faGift,
	faShoppingCart,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faSparkles} from '@fortawesome/pro-regular-svg-icons';

const ClientActivities = ({
	activities,
	setNotifyProps,
	setShowNotify,
	client,
	setClient,
	setRefreshClient,
	setActiveView,
	setShowYearDialog,
	setYearDialogProps,
	setDateDialogProps,
	setShowDateDialog,
}) => {
	const clientId = client.id;
	const [currentActivity, setCurrentActivity] = React.useState({
		name: 'allActivities',
		title: 'All Activities',
		activeView: null,
	});
	const [selectedActivities, setSelectedActivities] = React.useState(
		activities
	);
	const types = activities.map((activity) => {
		return activity.activityType;
	});

	React.useEffect(() => {
		if (currentActivity.name === 'allActivities') {
			setSelectedActivities(activities);
		} else {
			const newActivities = activities.filter((activity) => {
				return activity.activityType === currentActivity.name;
			});
			setSelectedActivities(newActivities);
		}
	}, [activities, currentActivity]);
	async function updateLayette(layette) {
		const attrs = {
			id: clientId,
			layette: layette,
		};
		const response = await API.graphql({
			query: mutations.updateClient,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setClient({...client, layette: response.data.updateClient.layette});
		setRefreshClient(true);
		return response;
	}
	async function deleteNewLayette(layette) {
		const attrs = {
			id: layette,
		};
		const response = await API.graphql({
			query: mutations.deleteLayette,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteStoreVisit(storeVisit) {
		const attrs = {
			id: storeVisit,
		};
		const response = await API.graphql({
			query: mutations.deleteStoreVisit,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setShowNotify(false);
			setRefreshClient(true);
		}
		return response;
	}

	async function deleteClass(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteClass,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteVideo(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteVideo,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteHelp(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteHelp,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteSpecialService(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteSpecialService,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}

	async function createNewLayette(year) {
		console.log(year);
		const attrs = {
			clientID: client.id,
			date: year,
		};
		const response = await API.graphql({
			query: mutations.createLayette,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowYearDialog(false);
		// setActivityList(!activityList);
		setRefreshClient(true);
		return response;
	}

	async function createStoreVisit(date) {
		const attrs = {
			clientID: client.id,
			date: date,
		};
		console.log(attrs);
		const response = await API.graphql({
			query: mutations.createStoreVisit,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setShowDateDialog(false);
			setRefreshClient(true);
		}

		return response;
	}

	return (
		<ActivitiesDashboard>
			<ActivitiesFilters
				types={types}
				currentActivity={currentActivity}
				setCurrentActivity={setCurrentActivity}
			/>
			<section>
				<ColumnHeader>
					<h2>{currentActivity.title}</h2>
					{currentActivity.name !== 'allActivities' &&
					currentActivity.name !== 'newLayette' &&
					currentActivity.name !== 'storeVisit' ? (
						<ClientActionButton
							type="button"
							onClick={(e) => {
								setActiveView(currentActivity.activeView);
							}}>
							<FontAwesomeIcon icon={faPlus} />
							<span>Add {currentActivity.title}</span>
						</ClientActionButton>
					) : currentActivity.name === 'newLayette' ? (
						<ClientActionButton
							onClick={() => {
								setYearDialogProps({
									message: `Select a year:`,
									confirm: 'Add Layette',
									cancel: () => setShowYearDialog(false),
									action: (props) => createNewLayette(props),
								});
								setShowYearDialog(true);
							}}>
							<FontAwesomeIcon icon={faPlus} />
							<span>Add Layette</span>
						</ClientActionButton>
					) : currentActivity.name === 'storeVisit' ? (
						<ClientActionButton
							type="button"
							onClick={() => {
								setDateDialogProps({
									message: `Select a date:`,
									confirm: 'Add Store Visit',
									cancel: () => setShowDateDialog(false),
									action: (props) => createStoreVisit(props),
								});
								setShowDateDialog(true);
							}}>
							<FontAwesomeIcon icon={faPlus} />
							<span>Add Store Visit</span>
						</ClientActionButton>
					) : null}
				</ColumnHeader>
				{selectedActivities.map((item, key) => {
					// console.log(item);
					switch (item.activityType) {
						case 'class':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faGraduationCap} />
									<div>
										<span>Class</span>
										<p>{item.name}</p>
										<p>
											{formatDate(
												new Date(item.completion_date)
											)}
										</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this class?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteClass(item.id),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'video':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faVideo} />
									<div>
										<span>Video</span>
										<p>{item.video_id}</p>
										<p>{formatDate(new Date(item.date))}</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this video?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteVideo(item.id),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'help':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faHandshake} />
									<div>
										<span>Emergency Help</span>
										<p>{item.helpName}</p>
										<p>
											{new Date(item.date).getFullYear()}
										</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this help?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteHelp(item.id),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'service':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faSparkles} />
									<div>
										<span>Special Service</span>
										<p>{item.serviceName}</p>
										<p>{formatDate(new Date(item.date))}</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this service?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteSpecialService(
														item.id
													),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'layette':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faGift} />
									<div>
										<span>Layette</span>
										<p>{item.layette}</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this layette?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													updateLayette(null),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'newLayette':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faGift} />
									<div>
										<span>Layette</span>
										<p>{item.layette.date}</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this layette?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteNewLayette(
														item.layette.id
													),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						case 'storeVisit':
							return (
								<DisplayItem key={key}>
									<FontAwesomeIcon icon={faShoppingCart} />
									<div>
										<span>Store Visit</span>
										<p>{formatDate(new Date(item.date))}</p>
									</div>
									<ActivityDeleteButton
										type="button"
										onClick={() => {
											setNotifyProps({
												message: `Are you sure you want to delete this store visit?`,
												confirm: 'Delete',
												cancel: () =>
													setShowNotify(false),
												action: () =>
													deleteStoreVisit(item.id),
											});
											setShowNotify(true);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ActivityDeleteButton>
								</DisplayItem>
							);
						default:
							return ``;
					}
				})}
			</section>
		</ActivitiesDashboard>
	);
};

export default ClientActivities;
