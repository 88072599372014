/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Notify from '../Components/Notify';

import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import IncentivesForm from '../Forms/IncentivesForm';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const IncentivesDashboard = () => {
	const [incentives, setIncentives] = React.useState();
	const [selectedIncentive, setSelectedIncentive] = React.useState();
	const [refreshIncentives, setRefreshIncentives] = React.useState(false);
	const [activeView, setActiveView] = React.useState('incentives');
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const incentivesForm = useForm({
		defaultValues: selectedIncentive,
	});

	async function getIncentives() {
		const response = await API.graphql({
			query: queries.listIncentives,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	function deleteIncentive(incentiveId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: incentiveId,
			};
			const response = await API.graphql({
				query: mutations.deleteIncentive,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (response.data.deleteIncentive.id === incentiveId) {
				setShowNotify(false);
				setRefreshIncentives(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		const incentiveData = getIncentives();
		incentiveData
			.then((res) => setIncentives(res.data.listIncentives.items))
			.catch((err) => checkToken());
		console.log(incentives);
		setRefreshIncentives(false);
	}, [refreshIncentives]);

	return !incentives ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'incentives' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>Incentives</h1>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) => setActiveView('addIncentive')}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add Incentive</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>

					<ItemList>
						<ItemListTitles>
							<div className="item">
								<p>Name</p>
							</div>
							<div className="item">
								<p>Description</p>
							</div>
							<div className="item">
								<p>Actions</p>
							</div>
						</ItemListTitles>
						{incentives.map((incentive, key) => (
							<ItemRow key={key}>
								<div className="item">
									<p>{incentive.name}</p>
								</div>
								<div className="item">
									<p>{incentive.description}</p>
								</div>

								<div className="action">
									<button
										type="button"
										title="Edit Incentive"
										onClick={() => {
											setSelectedIncentive(incentive);
											setActiveView('editIncentive');
										}}>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										type="button"
										title="Delete Incentive"
										onClick={() => {
											deleteIncentive(incentive.id, {
												message:
													'Are you sure you want to delete this Incentive?',
												confirm: 'Delete',
												cancel: 'Cancel',
												action: {},
											});
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</button>
								</div>
							</ItemRow>
						))}
					</ItemList>
				</>
			) : null}
			{activeView === 'addIncentive' ? (
				<>
					<ColumnHeader>
						<h1>Add Incentive</h1>
						<button
							type="button"
							onClick={(e) => {
								incentivesForm.reset();
								setActiveView('incentives');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<IncentivesForm
						setActiveView={setActiveView}
						incentivesForm={incentivesForm}
						setRefreshIncentives={() => {
							setRefreshIncentives(true);
						}}
					/>
				</>
			) : null}
			{activeView === 'editIncentive' ? (
				<>
					<ColumnHeader>
						<h1>Edit Incentive</h1>
						<button
							type="button"
							onClick={(e) => {
								incentivesForm.reset();
								setActiveView('incentives');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<IncentivesForm
						setActiveView={setActiveView}
						editMode={true}
						incentive={selectedIncentive}
						incentivesForm={incentivesForm}
						setRefreshIncentives={() => {
							setRefreshIncentives(true);
						}}
					/>
				</>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default IncentivesDashboard;
