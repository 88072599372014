import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {TextField, Button} from '@material-ui/core';

const NoteForm = ({user, client, note, editMode, noteForm, setActive}) => {
	const {control, handleSubmit} = noteForm;

	async function createNote(newNote) {
		const response = await API.graphql({
			query: mutations.createClientNote,
			variables: {input: newNote},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			noteForm.reset();
			setActive('client');
		}

		return response;
	}
	async function updateNote(existingNote) {
		console.log(existingNote);
		const response = await API.graphql({
			query: mutations.updateClientNote,
			variables: {input: existingNote},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			noteForm.reset();
			setActive('client');
		}

		return response;
	}

	const onSubmit = (data) => {
		let noteValues = data;
		noteValues.clientID = client.id;
		noteValues.clientFirstname = client.firstName;
		noteValues.clientLastname = client.lastName;
		noteValues.createdByUser = user.username;
		noteValues.createdByUserName = user.name;
		noteValues.type = 'note';
		let parsed = Object.keys(noteValues)
			.filter((k) => noteValues[k] != null)
			.reduce((a, k) => ({...a, [k]: noteValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		// console.log(parsed);
		if (!editMode) {
			createNote(parsed);
		} else {
			parsed.id = note.id;
			updateNote(parsed);
		}
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DetailItem>
					<FieldWrapper>
						{/* <FontAwesomeIcon icon={faStickyNote} size="lg" /> */}

						<Controller
							name="note"
							control={control}
							render={({field}) => (
								<TextField
									multiline
									label="Note"
									rows={8}
									variant="outlined"
									defaultValue={(note && note.note) || ''}
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default NoteForm;
