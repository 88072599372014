/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	formatPhoneNumber,
	formatDate,
	fromDateString,
	calculateAge,
} from '../lib/helpers';

import {useAuthState} from '../Context/context';
import {checkToken} from '../Context/actions';
import {
	ContentContainer,
	DashboardTitle,
	DisplayItem,
	ClientEditHeader,
	DashboardTabs,
	ClientData,
	ClientAddChild,
	ChildColumn,
	ClientActionButton,
	ColumnHeader,
	ProfilePhoto,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import ChildCard from '../Components/ChildCard';
import API from '@aws-amplify/api';
import {Storage} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import CreateClientForm from '../Forms/ClientForm';
import ChildFormComponent from '../Forms/ChildForm';
import NoteFormComponent from '../Forms/NoteForm';
import ClassFormComponent from '../Forms/ClassForm';
import VideoFormComponent from '../Forms/VideoForm';
import HelpFormComponent from '../Forms/EmergencyHelpForm';
import ServiceFormComponent from '../Forms/SpecialServiceForm';

import ClientActivities from './ClientActivities';
import ClientNotes from './ClientNotes';

import Notify from '../Components/Notify';
import YearSelectDialog from '../Components/YearSelectDialog';
import DateSelectDialog from '../Components/DateSelectDialog';
import ImageUploadDialog from '../Components/ImageUploadDialog';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPhone,
	faEnvelope,
	faPlus,
	faBan,
	faUser,
	faPen,
} from '@fortawesome/free-solid-svg-icons';
import {
	faEdit,
	faAddressBook,
	faCreditCardBlank,
	faRingsWedding,
	faUniversity,
	faCalendarWeek,
} from '@fortawesome/pro-regular-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faUserFriends} from '@fortawesome/pro-duotone-svg-icons';

const ClientDetail = () => {
	const [client, setClient] = React.useState();
	const [clientId, setClientId] = React.useState();
	const [refreshClient, setRefreshClient] = React.useState(false);
	const [activeView, setActiveView] = React.useState('client');
	const [selectedChild, setSelectedChild] = React.useState({});
	const [selectedNote, setSelectedNote] = React.useState({});
	const [notes, setNotes] = React.useState([]);
	const [selectedClass, setSelectedClass] = React.useState({});
	const [selectedVideo, setSelectedVideo] = React.useState({});
	const [selectedHelp, setSelectedHelp] = React.useState({});
	const [annualHelps, setAnnualHelps] = React.useState([]);
	const [selectedService, setSelectedService] = React.useState({});
	const [activityList, setActivityList] = React.useState(false);
	const [profilePhoto, setProfilePhoto] = React.useState();

	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [yearDialogProps, setYearDialogProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [dateDialogProps, setDateDialogProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [imageUploadProps, setImageUploadProps] = React.useState({});

	const [showNotify, setShowNotify] = React.useState(false);
	const [showYearDialog, setShowYearDialog] = React.useState(false);
	const [showDateDialog, setShowDateDialog] = React.useState(false);
	const [showImageUpload, setShowImageUpload] = React.useState(false);

	const [activities, setActivities] = React.useState([]);

	const {user} = useAuthState();
	// console.log(user);
	const [currentUser, setCurrentUser] = React.useState({
		username: user.username,
		name: user.signInUserSession.idToken.payload.name,
	});

	async function getClient(clientId) {
		const response = await API.graphql({
			query: queries.getClient,
			variables: {id: clientId, limit: 500},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	async function deleteChild(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteChild,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setShowNotify(false);
			setActiveView('client');
			setRefreshClient(true);
		}

		return response;
	}
	async function createNewLayette(year) {
		console.log(year);
		const attrs = {
			clientID: client.id,
			date: year,
		};
		const response = await API.graphql({
			query: mutations.createLayette,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowYearDialog(false);
		setActivityList(!activityList);
		setRefreshClient(true);
		return response;
	}

	async function createStoreVisit(date) {
		const attrs = {
			clientID: client.id,
			date: date,
		};
		console.log(attrs);
		const response = await API.graphql({
			query: mutations.createStoreVisit,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setShowDateDialog(false);
			setRefreshClient(true);
		}

		return response;
	}

	function showEditMode(e) {
		// console.log(client);
		setActiveView('edit');
		// setEditMode(!editMode);
		// console.log(editMode);
	}
	function showAddChild(e) {
		setActiveView('addChild');
	}

	const form = useForm({
		defaultValues: client,
	});
	const childForm = useForm({
		defaultValues: selectedChild,
	});
	const noteForm = useForm({
		defaultValues: selectedNote,
	});
	const classForm = useForm({
		defaultValues: selectedClass,
	});
	const videoForm = useForm({
		defaultValues: selectedVideo,
	});
	const helpForm = useForm({
		defaultValues: selectedHelp,
	});
	const serviceForm = useForm({
		defaultValues: selectedService,
	});

	React.useEffect(() => {
		const currentClient = window.location.pathname.split('/clients/')[1];
		setClientId(currentClient);
		const clientData = getClient(currentClient);
		clientData
			.then((res) => {
				const data = res.data.getClient;
				console.log(data);
				Storage.get(data.id)
					.then((url) => {
						var myRequest = new Request(url);
						fetch(myRequest).then(function (response) {
							if (response.status === 200) {
								setImageUploadProps({
									...imageUploadProps,
									profilePhoto: url,
								});
								setProfilePhoto(url);
							} else {
								setImageUploadProps({
									...imageUploadProps,
									profilePhoto: null,
								});
								setProfilePhoto(null);
							}
						});
					})
					.catch((err) => console.log(err));
				const modifiedDate = formatDate(new Date(data.updatedAt));
				const formattedPhone = formatPhoneNumber(data.phone);

				const birthdateObj = data.birthdate
					? fromDateString(data.birthdate)
					: {};

				const currentHelps = data.helps.items.filter((item) => {
					const year = new Date(item.createdAt).getFullYear();
					return year === new Date().getFullYear();
				});
				setAnnualHelps(currentHelps);
				const allActivities = [
					...data.classes.items
						.sort((a, b) => {
							return (
								new Date(b.completion_date) -
								new Date(a.completion_date)
							);
						})
						.map((item) => {
							let el = item;
							el.activityType = 'class';
							return el;
						}),
					...data.videos.items
						.sort((a, b) => {
							return new Date(b.date) - new Date(a.date);
						})
						.map((item) => {
							let el = item;
							el.activityType = 'video';
							return el;
						}),
					...data.helps.items
						.sort((a, b) => {
							return new Date(b.date) - new Date(a.date);
						})
						.map((item) => {
							let el = item;
							el.activityType = 'help';
							return el;
						}),
					...data.specialServices.items
						.sort((a, b) => {
							return new Date(b.date) - new Date(a.date);
						})
						.map((item) => {
							let el = item;
							// console.log(el);
							el.activityType = 'service';
							return el;
						}),
					...data.storeVisits.items
						.sort((a, b) => {
							return new Date(b.date) - new Date(a.date);
						})
						.map((item) => {
							let el = item;
							el.activityType = 'storeVisit';
							return el;
						}),
				];
				if (data.layette) {
					allActivities.push({
						activityType: 'layette',
						layette: data.layette,
					});
				}
				if (data.newLayette) {
					data.newLayette.items.forEach((item) => {
						allActivities.push({
							activityType: 'newLayette',
							layette: item,
						});
					});
				}
				// console.log(allActivities);
				setActivities(allActivities);
				setNotes(data.notes.items);

				setClient({
					...res.data.getClient,
					modified: modifiedDate,
					phone: formattedPhone,
					birthmonth: birthdateObj.month,
					birthday: birthdateObj.day,
					birthyear: birthdateObj.year,
					birthObj: birthdateObj,
					activities: allActivities,
				});
				form.reset(client);
			})
			.catch((err) => checkToken());
		setRefreshClient(false);
	}, [activeView, refreshClient, form.reset]);

	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<DashboardTitle>
				<ProfilePhoto>
					<div className="photo">
						{profilePhoto ? (
							<img src={profilePhoto} alt="Profile Photo" />
						) : (
							<FontAwesomeIcon icon={faUser} />
						)}
					</div>
					<button
						type="button"
						className="edit-button"
						onClick={() => {
							setImageUploadProps({
								profilePhoto: profilePhoto,
								client: client.id,
								setProfilePhoto,
								cancel: () => setShowImageUpload(false),
							});
							setShowImageUpload(true);
						}}>
						<FontAwesomeIcon icon={profilePhoto ? faPen : faPlus} />
					</button>
				</ProfilePhoto>
				<div>
					<h1>
						{client.firstName} {client.lastName}
					</h1>
					<p style={{fontStyle: 'italic'}}>
						Last updated: {client.modified}
					</p>
				</div>
				<div className="dashboard-buttons">
					<ClientActionButton
						type="button"
						onClick={(e) => {
							console.log(e);
							setActiveView('addNote');
						}}>
						<FontAwesomeIcon icon={faPlus} />
						<span>Add Note</span>
					</ClientActionButton>
					<ClientActionButton
						type="button"
						onClick={() => {
							setDateDialogProps({
								message: `Select a date:`,
								confirm: 'Add Store Visit',
								cancel: () => setShowDateDialog(false),
								action: (props) => createStoreVisit(props),
							});
							setShowDateDialog(true);
						}}>
						<FontAwesomeIcon icon={faPlus} />
						<span>Add Store Visit</span>
					</ClientActionButton>
				</div>
			</DashboardTitle>
			<DashboardTabs active={activeView}>
				<button type="button" onClick={() => setActiveView('client')}>
					Profile
				</button>
				<button
					type="button"
					onClick={() => setActiveView('activities')}>
					Activities
					<span>({activities.length})</span>
				</button>
				<button type="button" onClick={() => setActiveView('notes')}>
					Notes
					{/* Add Count of notes */}
					<span>({notes.length})</span>
				</button>
			</DashboardTabs>
			{activeView === 'client' ? (
				<>
					<ClientData>
						<section>
							<ColumnHeader>
								<h2>Profile</h2>
								<button
									type="button"
									onClick={(e) => {
										showEditMode(e);
									}}>
									<FontAwesomeIcon icon={faEdit} />
									<span>Edit</span>
								</button>
							</ColumnHeader>
							<DisplayItem>
								<FontAwesomeIcon icon={faPhone} />
								<div>
									<span>Phone</span>
									<p>{client.phone}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon icon={faEnvelope} />
								<div>
									<span>Email</span>
									<p>{client.email}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faCalendarWeek}
									size="lg"
								/>
								<div>
									<span>Date of Birth</span>
									{client.birthdate ? (
										<>
											<p>
												{client.birthmonth}{' '}
												{client.birthday},{' '}
												{client.birthyear}
											</p>
											<p>
												{calculateAge(
													new Date(client.birthdate)
												)}{' '}
												years old
											</p>
										</>
									) : null}
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faAddressBook}
									size="lg"
								/>
								<div>
									<span>Address</span>
									<p>
										{client.address} <br />
										{client.city}, {client.state}{' '}
										{client.zip}
									</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faRingsWedding}
									size="lg"
								/>
								<div>
									<span>Marital Status</span>
									<p>{client.maritalStatus}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faUserFriends}
									size="lg"
								/>
								<div>
									<span>Preferred Language</span>
									<p>{client.preferredLanguage}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faUserFriends}
									size="lg"
								/>
								<div>
									<span>Ethnicity</span>
									<p>{client.ethnicity}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faUniversity}
									size="lg"
								/>
								<div>
									<span>Education</span>
									<p>{client.education}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faCreditCardBlank}
									size="lg"
								/>
								<div>
									<span>WIC Status</span>
									<p>{client.wicStatus}</p>
								</div>
							</DisplayItem>
						</section>
						<ChildColumn>
							<div>
								<ColumnHeader>
									<h2>Children</h2>
									<ClientActionButton
										type="button"
										onClick={(e) => {
											showAddChild(e);
										}}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Child</span>
									</ClientActionButton>
								</ColumnHeader>
								{client.children.items.map((child, key) => {
									return (
										<ChildCard
											key={key}
											child={child}
											setActiveView={setActiveView}>
											<button
												type="button"
												onClick={() => {
													childForm.reset(child);
													setSelectedChild(child);
													setActiveView('editChild');
												}}>
												<FontAwesomeIcon
													icon={faEdit}
												/>
											</button>
										</ChildCard>
									);
								})}
							</div>
							<div>
								<ColumnHeader style={{marginTop: '2rem'}}>
									<h2>Add Activities</h2>
								</ColumnHeader>
								<div>
									<ClientActionButton
										onClick={() =>
											setActiveView('addClass')
										}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Class</span>
									</ClientActionButton>
									<ClientActionButton
										onClick={() =>
											setActiveView('addVideo')
										}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Video</span>
									</ClientActionButton>
									<ClientActionButton
										onClick={() =>
											setActiveView('addHelp')
										}>
										<FontAwesomeIcon icon={faPlus} />
										<span>
											Add Emergency Help (
											{3 - annualHelps.length} remaining)
										</span>
									</ClientActionButton>
									<ClientActionButton
										onClick={() =>
											setActiveView('addService')
										}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Special Service</span>
									</ClientActionButton>
									<ClientActionButton
										onClick={() => {
											setYearDialogProps({
												message: `Select a year:`,
												confirm: 'Add Layette',
												cancel: () =>
													setShowYearDialog(false),
												action: (props) =>
													createNewLayette(props),
											});
											setShowYearDialog(true);
										}}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Layette</span>
									</ClientActionButton>
								</div>
							</div>
						</ChildColumn>
					</ClientData>
				</>
			) : null}
			{activeView === 'activities' ? (
				<ClientActivities
					activities={activities}
					setNotifyProps={setNotifyProps}
					setShowNotify={setShowNotify}
					client={client}
					setClient={setClient}
					setRefreshClient={setRefreshClient}
					setActiveView={setActiveView}
					setShowYearDialog={setShowYearDialog}
					setYearDialogProps={setYearDialogProps}
					setDateDialogProps={setDateDialogProps}
					setShowDateDialog={setShowDateDialog}
				/>
			) : null}
			{activeView === 'notes' ? (
				<ClientNotes
					notes={notes}
					setActiveView={setActiveView}
					setSelectedNote={setSelectedNote}
				/>
			) : null}
			{activeView === 'edit' ? (
				<>
					<ClientEditHeader>
						<ColumnHeader>
							<h1>Edit Client</h1>
							<button
								type="button"
								onClick={(e) => {
									form.reset();
									setActiveView('client');
								}}>
								<FontAwesomeIcon icon={faBan} />
								<span>Cancel</span>
							</button>
						</ColumnHeader>
						<p style={{fontStyle: 'italic'}}>
							Last updated: {client.modified}
						</p>
					</ClientEditHeader>
					<CreateClientForm
						editMode={true}
						client={client}
						form={form}
						setActive={setActiveView}
					/>
				</>
			) : null}
			{activeView === 'addChild' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Child</h1>
						<button
							type="button"
							onClick={(e) => {
								childForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ChildFormComponent
						client={clientId}
						childForm={childForm}
						editMode={false}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'editChild' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Edit Child</h1>
						<button
							type="button"
							onClick={(e) => {
								childForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
						<button
							type="button"
							onClick={() => {
								setNotifyProps({
									message: `Are you sure you want to delete this child?`,
									confirm: 'Delete',
									cancel: () => setShowNotify(false),
									action: () => {
										childForm.reset();
										setSelectedChild({});
										deleteChild(selectedChild.id);
									},
								});
								setShowNotify(true);
							}}>
							<FontAwesomeIcon icon={faTrashAlt} />
							<span>Delete</span>
						</button>
					</ColumnHeader>
					<ChildFormComponent
						client={clientId}
						child={selectedChild}
						childForm={childForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addNote' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Note</h1>
						<button
							type="button"
							onClick={(e) => {
								noteForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<NoteFormComponent
						user={currentUser}
						client={client}
						noteForm={noteForm}
						editMode={false}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'editNote' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Edit Note</h1>
						<button
							type="button"
							onClick={(e) => {
								noteForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<NoteFormComponent
						user={currentUser}
						client={client}
						note={selectedNote}
						noteForm={noteForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addClass' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Class Attendance</h1>
						<button
							type="button"
							onClick={(e) => {
								classForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ClassFormComponent
						user={currentUser}
						client={client}
						classForm={classForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addVideo' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Video</h1>
						<button
							type="button"
							onClick={(e) => {
								videoForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<VideoFormComponent
						user={currentUser}
						client={client}
						videoForm={videoForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addHelp' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Emergency Help</h1>
						<button
							type="button"
							onClick={(e) => {
								helpForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<HelpFormComponent
						user={currentUser}
						client={client}
						helpForm={helpForm}
						editMode={true}
						setActive={setActiveView}
						setActivityList={setActivityList}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addService' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Special Service</h1>
						<button
							type="button"
							onClick={(e) => {
								helpForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ServiceFormComponent
						user={currentUser}
						client={client}
						serviceForm={serviceForm}
						editMode={true}
						setActive={setActiveView}
						setActivityList={setActivityList}
					/>
				</ClientAddChild>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
			<YearSelectDialog props={yearDialogProps} show={showYearDialog} />
			<DateSelectDialog props={dateDialogProps} show={showDateDialog} />
			<ImageUploadDialog
				props={imageUploadProps}
				show={showImageUpload}
			/>
		</ContentContainer>
	);
};

export default ClientDetail;
