import * as React from 'react';
import {PaginationContainer, Button} from '../Global/StyledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const Pagination = ({paginate, showPrev, showNext}) => {
	return (
		<PaginationContainer>
			{showPrev ? (
				<Button type="button" onClick={() => paginate('prev')}>
					<FontAwesomeIcon icon={faChevronLeft} />
					Previous Page
				</Button>
			) : null}
			{showNext ? (
				<Button
					type="button"
					style={{marginLeft: 'auto'}}
					onClick={() => paginate('next')}>
					Next Page
					<span>
						<FontAwesomeIcon icon={faChevronRight} />
					</span>
				</Button>
			) : null}
		</PaginationContainer>
	);
};

export default Pagination;
