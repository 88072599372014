import * as React from 'react';

import {ContentContainer, DashboardTitle} from '../Global/StyledComponents';
import CreateUserForm from '../Admin/CreateUserForm';

import {useForm} from 'react-hook-form';

const NewClient = () => {
	const form = useForm();

	return (
		<ContentContainer>
			<DashboardTitle>
				<h1>Add New User</h1>
			</DashboardTitle>
			<CreateUserForm form={form} />
		</ContentContainer>
	);
};

export default NewClient;
