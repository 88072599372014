import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {TextField, Button, MenuItem} from '@material-ui/core';

import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import DatePicker from '../Components/DatePicker';
import {toDateString, fromDateString} from '../lib/helpers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faGift,
	faGraduationCap,
	faLandmark,
} from '@fortawesome/free-solid-svg-icons';
import {checkToken} from '../Context/actions';

const ClassForm = ({
	user,
	client,
	classObj,
	editMode,
	classForm,
	setActive,
}) => {
	const [pillars, setPillars] = React.useState();
	const [incentives, setIncentives] = React.useState();
	const {control, handleSubmit} = classForm;

	async function getPillars() {
		const response = await API.graphql({
			query: queries.listPillars,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getIncentives() {
		const response = await API.graphql({
			query: queries.listIncentives,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	React.useEffect(() => {
		const pillars = getPillars();
		pillars
			.then((res) => setPillars(res.data.listPillars.items))
			.catch((err) => checkToken());
		const incentives = getIncentives();
		incentives
			.then((res) => setIncentives(res.data.listIncentives.items))
			.catch((err) => checkToken());
	}, []);

	async function createClass(newClass) {
		const response = await API.graphql({
			query: mutations.createClass,
			variables: {input: newClass},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			classForm.reset();
			setActive('client');
		}

		return response;
	}

	const onSubmit = (data) => {
		let classValues = data;
		classValues.clientID = client.id;

		classValues.completion_date = toDateString(
			data.classmonth,
			data.classday,
			data.classyear
		);
		let parsed = Object.keys(classValues)
			.filter((k) => classValues[k] != null)
			.reduce((a, k) => ({...a, [k]: classValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;
		delete parsed.classmonth;
		delete parsed.classday;
		delete parsed.classyear;

		// console.log(parsed);
		createClass(parsed);
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DatePicker
					form={classForm}
					groupLabel="Completion Date"
					initialValue={
						(classObj &&
							fromDateString(classObj.completion_date)) || {
							month: 'January',
						}
					}
					nameObj={{
						month: 'classmonth',
						day: 'classday',
						year: 'classyear',
					}}
				/>
				<DetailItem>
					<FieldWrapper>
						<FontAwesomeIcon icon={faGraduationCap} size="lg" />
						<Controller
							name="name"
							control={control}
							render={({field}) => (
								<TextField
									label="Class Name"
									variant="outlined"
									defaultValue={
										(classObj && classObj.name) || ''
									}
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faLandmark} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="pillar-field"
							name="pillarID"
							label="Pillar"
							defaultValue={(classObj && classObj.pillarID) || ''}
							variant="outlined">
							{pillars &&
								pillars.map((pillar, key) => (
									<MenuItem key={key} value={pillar.id}>
										{pillar.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faGift} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="incentive-field"
							name="incentiveID"
							label="Incentive"
							defaultValue={
								(classObj && classObj.incentive) || ''
							}
							variant="outlined">
							{incentives &&
								incentives.map((incentive, key) => (
									<MenuItem key={key} value={incentive.id}>
										{incentive.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default ClassForm;
