import * as React from 'react';
import {ModalContainer, Modal, Flex} from '../Global/StyledComponents';

const YearSelectDialog = ({props, show}) => {
	const today = new Date();
	const year = today.getFullYear();
	const yearOptions = () => {
		let options = [];
		for (let i = 2010; i < year + 1; i++) {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return options;
	};

	const [selectedYear, setSelectedYear] = React.useState('2022');
	const handleChange = (e) => {
		setSelectedYear(e.target.value);
		// console.log(selectedYear);
	};

	return (
		<ModalContainer active={show}>
			<Modal>
				<Flex style={{justifyContent: 'space-between'}}>
					<p>{props.message}</p>
					<select defaultValue={selectedYear} onChange={handleChange}>
						{yearOptions()}
					</select>
				</Flex>
				<Flex>
					<button
						type="button"
						className="warning"
						onClick={() => props.action(selectedYear)}>
						{props.confirm}
					</button>
					<button type="button" onClick={() => props.cancel()}>
						Cancel
					</button>
				</Flex>
			</Modal>
		</ModalContainer>
	);
};

export default YearSelectDialog;
