import * as React from 'react';

import {ContentContainer, DashboardTitle} from '../Global/StyledComponents';
import ClientForm from '../Forms/ClientForm';

import {useForm} from 'react-hook-form';

const NewClient = () => {
	const form = useForm();

	return (
		<ContentContainer>
			<DashboardTitle>
				<h1>Add New Client</h1>
			</DashboardTitle>
			<ClientForm editMode={false} client={{}} form={form} />
		</ContentContainer>
	);
};

export default NewClient;
