import * as React from '../Components/ChildCard';
import {formatDate, calculateAge} from '../lib/helpers';
import {ChildItem} from '../Global/StyledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChild, faBaby} from '@fortawesome/free-solid-svg-icons';

const ChildCard = ({child, children}) => {
	return (
		<ChildItem>
			{child.pregnancy ? (
				<FontAwesomeIcon icon={faBaby} />
			) : (
				<FontAwesomeIcon icon={faChild} />
			)}
			<div>
				<p>
					{child.firstName} {child.lastName}
				</p>
				{child.pregnancy ? (
					<div>
						<span>Due Date:</span>
						<span> {formatDate(new Date(child.dueDate))}</span>
					</div>
				) : (
					<div>
						<span>{child.gender}</span>
						<br />
						<span>
							{calculateAge(new Date(child.birthdate))} years old
						</span>
					</div>
				)}
			</div>
			{children}
		</ChildItem>
	);
};

export default ChildCard;
