import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {ClientActionButton, ContentContainer} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import {useAuthState} from '../Context/context';

import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const Import = () => {
	const {user} = useAuthState();
	const [clientsList, setClientsList] = React.useState([]);
	const [clickCount, setClickCount] = React.useState(0);

	async function getImportClients() {
		const response = await fetch(
			'https://n2nv4hpz56.execute-api.us-east-2.amazonaws.com/dev/',
			{
				method: 'GET',
			}
		);
		return response.json();
	}

	async function createNote(newNote) {
		const response = await API.graphql({
			query: mutations.createClientNote,
			variables: {input: newNote},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);

		return response;
	}
	async function createHelp(newHelp) {
		const response = await API.graphql({
			query: mutations.createHelp,
			variables: {input: newHelp},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}

	async function createClient(newClient) {
		const layette = () => {
			let regex = /\d+/;
			let Layette1 = newClient['2016-2018 Layette'].match(regex);
			let Layette2 = newClient['2019-2021 Layette'].match(regex);

			let layette = Layette1 || Layette2;
			return layette && layette[0];
		};

		const formatted = {
			type: 'client',
			firstName: newClient['Salutation Name'],
			lastName: newClient['Last Name'],
			address: newClient['Address Line 1'],
			city: newClient['City'],
			state: newClient['State'],
			zip: newClient['Zip Code'],
			phone: newClient['Primary Phone'],
			layette: layette(),
		};

		const clientResponse = await API.graphql({
			query: mutations.createClient,
			variables: {input: formatted},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(clientResponse);

		const clientID = clientResponse.data.createClient.id;

		const listNote = {
			clientID: clientID,
			clientFirstname: newClient['Salutation Name'],
			clientLastname: newClient['Last Name'],
			note: newClient['Mailing Code'],
			type: 'note',
			createdByUser: 'Imported',
			createdByUserName: 'Imported',
		};
		createNote(listNote);

		const childrenNote = {
			clientID: clientID,
			clientFirstname: newClient['Salutation Name'],
			clientLastname: newClient['Last Name'],
			note: newClient['Individual Names'],
			type: 'note',
			createdByUser: 'Imported',
			createdByUserName: 'Imported',
		};
		createNote(childrenNote);

		const helps = () => {
			let regex = /\d{4}/g;

			let Help1 = newClient['2016-2018 Diapers and Formula'].match(regex);
			let Help2 = newClient['2019-2021 Diapers and Formula'].match(regex);

			let helps = [...(Help1 || []), ...(Help2 || [])];

			helps.map((help) => {
				const formattedHelp = {
					clientID: clientID,
					helpName: 'Diapers and Formula',
					helpID: 'c267d218-a712-4f04-9ee7-80c3aa6b4fa3',
					date: `${help}-01-01T06:00:00.000Z`,
				};
				console.log(formattedHelp);
				createHelp(formattedHelp);
				return formattedHelp;
			});
		};
		helps();
		return clientResponse;
	}

	React.useEffect(() => {
		console.log(clientsList);
	}, [clientsList]);

	return !user ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<ClientActionButton
				type="button"
				onClick={(e) => {
					getImportClients().then((data) => {
						console.log(data.message);
						const items = data.message;
						const size = 200;
						var clientsChunks = [];
						for (var i = 0; i < items.length; i += size) {
							clientsChunks.push(items.slice(i, i + size));
						}
						setClientsList(clientsChunks);
					});
				}}>
				<FontAwesomeIcon icon={faPlus} />
				<span>Get Clients</span>
			</ClientActionButton>
			<ClientActionButton
				type="button"
				onClick={async (e) => {
					if (clickCount < clientsList.length) {
						clientsList[clickCount].forEach(function (client) {
							// console.log(client);
							createClient(client);
							setClickCount(clickCount + 1);
							console.log(clickCount);
						});
					} else {
						alert('No more clients');
					}
				}}>
				<FontAwesomeIcon icon={faPlus} />
				<span>Import Clients</span>
			</ClientActionButton>
		</ContentContainer>
	);
};

export default Import;
