/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {Link} from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';

import {useQuery} from '../lib/hooks';

import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Pagination from '../Components/Pagination';
import SearchBar from '../Layout/SearchBar';
import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faUserEdit, faPlus} from '@fortawesome/pro-regular-svg-icons';
import Notify from '../Components/Notify';
import {checkToken} from '../Context/actions';

const searchClient = algoliasearch(
	'5NXAX43H18',
	'3319ec918cccc0d77fac6e378c7b6cca'
);
const searchIndex = searchClient.initIndex('Treehouse-client-prod');

const ClientsDashboard = () => {
	const urlParams = useQuery();
	const searchTerm = urlParams.get('search');

	const [clients, setClients] = React.useState();
	const [nextToken, setNextToken] = React.useState();
	const [nextNextToken, setNextNextToken] = React.useState();
	const [prevTokens, setPrevTokens] = React.useState([]);

	const [refreshClients, setRefreshClients] = React.useState(false);
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	async function search(term) {
		searchIndex.search(term, {hitsPerPage: 50}).then(({hits}) => {
			// console.log(hits);
			setClients(hits);
		});
	}

	async function getClients(token) {
		const response = await API.graphql({
			query: queries.clientsByDate,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
			variables: {
				type: 'client',
				limit: 10,
				sortDirection: 'DESC',
				nextToken: token,
			},
		});
		console.log(response);
		return response;
	}

	function deleteClient(clientId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: clientId,
			};
			const response = await API.graphql({
				query: mutations.deleteClient,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (response.data.deleteClient.id === clientId) {
				setShowNotify(false);
				setRefreshClients(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		if (searchTerm) {
			search(searchTerm);
		} else {
			const clientData = getClients(nextToken);
			clientData
				.then((res) => {
					setNextNextToken(res.data.clientsByDate.nextToken);
					setClients(res.data.clientsByDate.items);
				})
				.catch((err) => {
					console.log(err);
					checkToken();
				});
		}
		// console.log(clients);
	}, [refreshClients, nextToken, searchTerm]);

	const paginate = (direction) => {
		const paginationTokens = prevTokens;
		if (direction === 'next') {
			paginationTokens.push(nextToken);
			setPrevTokens(paginationTokens);
			setNextToken(nextNextToken);
		} else if (direction === 'prev') {
			setNextToken(paginationTokens.pop());
		}
	};

	return !clients ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<>
			<ContentContainer>
				<DashboardTitle>
					<div>
						<h1>Clients</h1>
					</div>
					<div>
						<Link to="/add-client">
							<ClientActionButton type="button">
								<FontAwesomeIcon icon={faPlus} />
								<span>Add Client</span>
							</ClientActionButton>
						</Link>
					</div>
					<div style={{marginLeft: '4rem'}}>
						<SearchBar location={'clients'} />
					</div>
				</DashboardTitle>
				<ItemList>
					<ItemListTitles>
						<div className="item">
							<p>Name</p>
						</div>
						<div className="item">
							<p>Phone</p>
						</div>
						<div className="item">
							<p>Email</p>
						</div>
						<div className="item">
							<p>Actions</p>
						</div>
					</ItemListTitles>
					{clients.map((client, key) => (
						<ItemRow key={key}>
							<div className="item">
								<p>
									<Link to={`/clients/${client.id}`}>
										{client.firstName} {client.lastName}
									</Link>
								</p>
							</div>
							<div className="item">
								<p>{client.phone}</p>
							</div>
							<div className="item">
								<p>{client.email}</p>
							</div>

							<div className="action">
								<button type="button" title="Edit Client">
									<FontAwesomeIcon icon={faUserEdit} />
								</button>
								<button
									type="button"
									title="Delete Client"
									onClick={() => {
										console.log(client);
										deleteClient(client.id, {
											message:
												'Are you sure you want to delete this client?',
											confirm: 'Delete',
											cancel: 'Cancel',
											action: {},
										});
									}}>
									<FontAwesomeIcon icon={faTrashAlt} />
								</button>
							</div>
						</ItemRow>
					))}
				</ItemList>
				<Pagination
					paginate={paginate}
					showPrev={prevTokens.length > 0}
					showNext={nextNextToken}
				/>
				<Notify props={notifyProps} show={showNotify} />
			</ContentContainer>
		</>
	);
};

export default ClientsDashboard;
