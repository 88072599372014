/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {Link} from 'react-router-dom';
import {
	listUsers,
	listUsersInGroup,
	addUserToGroup,
	removeUserFromGroup,
	deleteUser,
} from '../Admin/AdminAPIs';

import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ClientActionButton,
} from '../Global/StyledComponents';

import Loader from '../Components/Loader';
import Notify from '../Components/Notify';
import UserRow from '../Components/UserRow';
import Pagination from '../Components/Pagination';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const AdminDashboard = () => {
	const [usersList, setUsersList] = React.useState();
	const [refreshUsers, setRefreshUsers] = React.useState(false);
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);
	const [nextToken, setNextToken] = React.useState();
	const [nextNextToken, setNextNextToken] = React.useState();
	const [prevTokens, setPrevTokens] = React.useState([]);

	async function getUsersGroup() {
		const usersGroup = await listUsersInGroup('users')
			.then((users) => {
				return users.Users;
			})
			.catch((err) => checkToken());
		return usersGroup;
	}
	async function getAdminsGroup() {
		const adminsGroup = await listUsersInGroup('admins')
			.then((users) => {
				return users.Users;
			})
			.catch((err) => checkToken());
		return adminsGroup;
	}
	async function fetchUsers() {
		const users = await listUsers(10, nextToken)
			.then((res) => {
				setNextNextToken(res.nextToken);
				return res.rest.Users;
			})
			.catch((err) => checkToken());
		return users;
	}

	async function saveEdits(data) {
		const {username, groupname} = data;
		if (groupname === 'admins') {
			const response = await addUserToGroup(username, 'admins');
			if (response) {
				setRefreshUsers(true);
			}
		} else {
			const response = await removeUserFromGroup(username, 'admins');
			if (response) {
				setRefreshUsers(true);
			}
		}
	}

	function removeUser(username, notify) {
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};
		setNotifyProps(notifyData);
		setShowNotify(true);
		function cancelAction() {
			setShowNotify(false);
		}
		async function deleteAction() {
			const deleted = await deleteUser(username)
				.then((res) => {
					// console.log(res);
					if (res) {
						setRefreshUsers(true);
						setShowNotify(false);
					}
				})
				.catch((err) => checkToken());
			return deleted;
		}
	}
	React.useEffect(async () => {
		// const usersCache = JSON.parse(localStorage.getItem('adminUsersCache'));
		// if (usersCache && !refreshUsers) {
		// 	// console.log('userCache');
		// 	setUsersList(usersCache);
		// } else {
		// console.log('fetch users');
		const allUsers = await fetchUsers();
		// console.log(allUsers);
		const usersGroup = await getUsersGroup();
		const adminsGroup = await getAdminsGroup();
		const usersNotInGroup = allUsers.filter((user) => {
			return !usersGroup.some(
				(groupUser) => user.Username === groupUser.Username
			);
		});
		if (usersNotInGroup.length) {
			usersNotInGroup.forEach(async (user) => {
				await addUserToGroup(user.Username, 'users');
			});
		}

		const usersWithGroups = allUsers.map((user) => {
			const isAdmin = adminsGroup.some(
				(adminGroup) => user.Username === adminGroup.Username
			);
			// const isUser = usersGroup.some(
			// 	(userGroup) => user.Username === userGroup.Username
			// );
			return {
				username: user.Username,
				name: user.Attributes.find((el) => el.Name === 'name').Value,
				email: user.Attributes.find((el) => el.Name === 'email').Value,
				status: !user.Enabled ? 'DISABLED' : user.UserStatus,
				role: isAdmin ? 'Admin' : 'User',
			};
		});
		localStorage.setItem(
			'adminUsersCache',
			JSON.stringify(usersWithGroups)
		);
		setUsersList(usersWithGroups);
		// }
		setRefreshUsers(false);
		// console.log(usersList);
	}, [refreshUsers, nextToken]);

	const paginate = (direction) => {
		const paginationTokens = prevTokens;
		if (direction === 'next') {
			paginationTokens.push(nextToken);
			setPrevTokens(paginationTokens);
			setNextToken(nextNextToken);
		} else if (direction === 'prev') {
			setNextToken(paginationTokens.pop());
		}
	};

	return !usersList ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<DashboardTitle>
				<h1>Manage Users</h1>
				<Link to="/add-user">
					<ClientActionButton type="button">
						<FontAwesomeIcon icon={faPlus} />
						<span>Add User</span>
					</ClientActionButton>
				</Link>
			</DashboardTitle>
			<ItemList>
				<ItemListTitles>
					<div className="item">
						<p>Name</p>
					</div>
					<div className="item">
						<p>Email</p>
					</div>
					<div className="item">
						<p>Status</p>
					</div>
					<div className="item">
						<p>Role</p>
					</div>
					<div className="item">
						<p>Actions</p>
					</div>
				</ItemListTitles>
				{usersList.map((user, key) => {
					return (
						<UserRow
							key={key}
							user={user}
							saveFunction={saveEdits}
							deleteFunction={removeUser}
						/>
					);
				})}
			</ItemList>
			<Pagination
				paginate={paginate}
				showPrev={prevTokens.length > 0}
				showNext={nextNextToken}
			/>
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default AdminDashboard;
