import * as React from 'react';
import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {Form, DetailItem, FieldWrapper} from '../Global/StyledComponents';
import {TextField, Button} from '@material-ui/core';

const SpecialServiceTypeForm = ({
	setActiveView,
	specialService,
	specialServicesForm,
	setRefreshSpecialServices,
	editMode,
}) => {
	async function createSpecialService(specialService) {
		console.log([specialService]);
		const response = await API.graphql({
			query: mutations.createSpecialServiceType,
			variables: {input: specialService},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}
	async function updateSpecialService(specialService) {
		console.log(specialService);
		const response = await API.graphql({
			query: mutations.updateSpecialServiceType,
			variables: {input: specialService},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}

	const {control, handleSubmit} = specialServicesForm;

	const onSubmit = (data) => {
		// console.log(data);
		let specialServiceValues = data;

		let parsed = Object.keys(specialServiceValues)
			.filter((k) => specialServiceValues[k] != null)
			.reduce((a, k) => ({...a, [k]: specialServiceValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		if (!editMode) {
			createSpecialService(parsed);
			setRefreshSpecialServices();
			setActiveView('specialServices');
			specialServicesForm.reset();
		} else {
			parsed.id = specialService.id;
			updateSpecialService(parsed);
			setRefreshSpecialServices();
			setActiveView('specialServices');
			specialServicesForm.reset();
		}
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="name"
						control={control}
						defaultValue={
							(specialService && specialService.name) || ''
						}
						render={({field}) => (
							<TextField
								label="Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="description"
						control={control}
						render={({field}) => (
							<TextField
								multiline
								label="Description (optional)"
								rows={8}
								variant="outlined"
								defaultValue={
									(specialService &&
										specialService.description) ||
									''
								}
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default SpecialServiceTypeForm;
