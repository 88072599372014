import {ModalContainer, Modal, Flex} from '../Global/StyledComponents';

const Notify = ({props, show}) => {
	return (
		<ModalContainer active={show}>
			<Modal>
				<p>{props.message}</p>
				<Flex>
					<button
						type="button"
						className="warning"
						onClick={() => props.action()}>
						{props.confirm}
					</button>
					<button type="button" onClick={() => props.cancel()}>
						Cancel
					</button>
				</Flex>
			</Modal>
		</ModalContainer>
	);
};

export default Notify;
