/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Notify from '../Components/Notify';

import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import HelpsTypeForm from '../Forms/HelpsTypeForm';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const HelpsDashboard = () => {
	const [helps, setHelps] = React.useState();
	const [selectedHelp, setSelectedHelp] = React.useState();
	const [refreshHelps, setRefreshHelps] = React.useState(false);
	const [activeView, setActiveView] = React.useState('helps');
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const helpsForm = useForm({
		defaultValues: selectedHelp,
	});

	async function getHelps() {
		const response = await API.graphql({
			query: queries.listHelpTypes,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	function deleteHelp(helpId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: helpId,
			};
			console.log(data);
			const response = await API.graphql({
				query: mutations.deleteHelpType,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (response.data.deleteHelpType.id === helpId) {
				setShowNotify(false);
				setRefreshHelps(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		const helpData = getHelps();
		helpData
			.then((res) => setHelps(res.data.listHelpTypes.items))
			.catch((err) => checkToken());
		console.log(helps);
		setRefreshHelps(false);
	}, [refreshHelps]);

	return !helps ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'helps' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>Emergency Helps</h1>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) => setActiveView('addHelp')}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add help</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>

					<ItemList>
						<ItemListTitles>
							<div className="item">
								<p>Name</p>
							</div>
							<div className="item">
								<p>Description</p>
							</div>
							<div className="item">
								<p>Actions</p>
							</div>
						</ItemListTitles>
						{helps.map((help, key) => (
							<ItemRow key={key}>
								<div className="item">
									<p>{help.name}</p>
								</div>
								<div className="item">
									<p>{help.description}</p>
								</div>

								<div className="action">
									<button
										type="button"
										title="Edit help"
										onClick={() => {
											setSelectedHelp(help);
											setActiveView('editHelp');
										}}>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										type="button"
										title="Delete help"
										onClick={() => {
											deleteHelp(help.id, {
												message:
													'Are you sure you want to delete this help?',
												confirm: 'Delete',
												cancel: 'Cancel',
												action: {},
											});
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</button>
								</div>
							</ItemRow>
						))}
					</ItemList>
				</>
			) : null}
			{activeView === 'addHelp' ? (
				<>
					<ColumnHeader>
						<h1>Add Emergency Help</h1>
						<button
							type="button"
							onClick={(e) => {
								helpsForm.reset();
								setActiveView('helps');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<HelpsTypeForm
						setActiveView={setActiveView}
						helpsForm={helpsForm}
						setRefreshHelps={() => {
							setRefreshHelps(true);
						}}
					/>
				</>
			) : null}
			{activeView === 'editHelp' ? (
				<>
					<ColumnHeader>
						<h1>Edit Emergency Help</h1>
						<button
							type="button"
							onClick={(e) => {
								helpsForm.reset();
								setActiveView('helps');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<HelpsTypeForm
						setActiveView={setActiveView}
						editMode={true}
						help={selectedHelp}
						helpsForm={helpsForm}
						setRefreshHelps={() => {
							setRefreshHelps(true);
						}}
					/>
				</>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default HelpsDashboard;
