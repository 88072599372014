import * as React from 'react';
import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {Form, DetailItem, FieldWrapper} from '../Global/StyledComponents';
import {TextField, Button} from '@material-ui/core';

const ReferrersForm = ({
	setActiveView,
	referrer,
	referrersForm,
	setRefreshReferrers,
	editMode,
}) => {
	async function createReferrer(referrer) {
		console.log([referrer]);
		const response = await API.graphql({
			query: mutations.createReferringAgency,
			variables: {input: referrer},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}
	async function updateReferrer(referrer) {
		console.log(referrer);
		const response = await API.graphql({
			query: mutations.updateReferringAgency,
			variables: {input: referrer},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}

	const {control, handleSubmit} = referrersForm;

	const onSubmit = (data) => {
		// console.log(data);
		let referrerValues = data;

		let parsed = Object.keys(referrerValues)
			.filter((k) => referrerValues[k] != null)
			.reduce((a, k) => ({...a, [k]: referrerValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		if (!editMode) {
			createReferrer(parsed);
			setRefreshReferrers();
			setActiveView('referrers');
			referrersForm.reset();
		} else {
			parsed.id = referrer.id;
			updateReferrer(parsed);
			setRefreshReferrers();
			setActiveView('referrers');
			referrersForm.reset();
		}
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="name"
						control={control}
						defaultValue={(referrer && referrer.name) || ''}
						render={({field}) => (
							<TextField
								label="Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="description"
						control={control}
						render={({field}) => (
							<TextField
								multiline
								label="Description (optional)"
								rows={8}
								variant="outlined"
								defaultValue={
									(referrer && referrer.description) || ''
								}
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default ReferrersForm;
