import * as React from 'react';
import {ModalContainer, Modal, Flex} from '../Global/StyledComponents';

import {Storage} from 'aws-amplify';

const ImageUploadDialog = ({props, show}) => {
	// console.log(props);

	const [imageFile, setImageFile] = React.useState();

	async function uploadImage(e) {
		e.preventDefault();
		const file = imageFile;
		try {
			await Storage.put(props.client, file, {
				level: 'public',
			});
			console.log('Upload Successful');
			await Storage.get(props.client)
				.then((url) => {
					var myRequest = new Request(url);
					fetch(myRequest).then(function (response) {
						if (response.status === 200) {
							props.setProfilePhoto(url);
							props.cancel();
						}
					});
				})
				.catch((err) => console.log(err));
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	}
	async function deletePhoto(e) {
		try {
			await Storage.remove(props.client).then((res) => {
				console.log(res);
				props.setProfilePhoto(null);
				props.cancel();
			});
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<ModalContainer active={show}>
			<Modal>
				<p style={{marginBottom: '2rem'}}>Upload Client Photo</p>
				<form onSubmit={uploadImage}>
					<Flex style={{justifyContent: 'space-between'}}>
						<input
							type="file"
							onChange={(e) => setImageFile(e.target.files[0])}
						/>
					</Flex>
					<Flex style={{marginTop: '2rem'}}>
						<button type="submit" className="warning">
							Save Image
						</button>
						<button type="button" onClick={() => props.cancel()}>
							Cancel
						</button>
					</Flex>
					{props.profilePhoto ? (
						<Flex style={{marginTop: '2rem'}}>
							<button type="button" onClick={deletePhoto}>
								Remove profile photo
							</button>
						</Flex>
					) : null}
				</form>
			</Modal>
		</ModalContainer>
	);
};

export default ImageUploadDialog;
