import * as React from 'react';
import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {Form, DetailItem, FieldWrapper} from '../Global/StyledComponents';
import {TextField, Button} from '@material-ui/core';

const IncentivesForm = ({
	setActiveView,
	incentive,
	incentivesForm,
	setRefreshIncentives,
	editMode,
}) => {
	async function createIncentive(incentive) {
		console.log([incentive]);
		const response = await API.graphql({
			query: mutations.createIncentive,
			variables: {input: incentive},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}
	async function updateIncentive(incentive) {
		const response = await API.graphql({
			query: mutations.updateIncentive,
			variables: {input: incentive},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}

	const {control, handleSubmit} = incentivesForm;

	const onSubmit = (data) => {
		console.log(data);
		let incentiveValues = data;

		let parsed = Object.keys(incentiveValues)
			.filter((k) => incentiveValues[k] != null)
			.reduce((a, k) => ({...a, [k]: incentiveValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		if (!editMode) {
			createIncentive(parsed);
			setRefreshIncentives();
			setActiveView('incentives');
			incentivesForm.reset();
		} else {
			parsed.id = incentive.id;
			updateIncentive(parsed);
			setRefreshIncentives();
			setActiveView('incentives');
			incentivesForm.reset();
		}
	};
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="name"
						control={control}
						defaultValue={(incentive && incentive.name) || ''}
						render={({field}) => (
							<TextField
								label="Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="description"
						control={control}
						render={({field}) => (
							<TextField
								multiline
								label="Description (optional)"
								rows={8}
								variant="outlined"
								defaultValue={
									(incentive && incentive.description) || ''
								}
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default IncentivesForm;
