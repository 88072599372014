import * as React from 'react';
import {ModalContainer, Modal, Flex} from '../Global/StyledComponents';
import {months} from '../lib/helpers';

const DateSelectDialog = ({props, show}) => {
	const today = new Date();
	const year = today.getFullYear();
	const yearOptions = () => {
		let options = [];
		for (let i = 2010; i < year + 1; i++) {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return options;
	};

	const [selectedMonth, setSelectedMonth] = React.useState(
		months[today.getMonth()].id
	);
	const [selectedDay, setSelectedDay] = React.useState(today.getDate());
	const [selectedYear, setSelectedYear] = React.useState(today.getFullYear());
	const handleMonthChange = (e) => {
		setSelectedMonth(e.target.value);
	};
	const handleDayChange = (e) => {
		setSelectedDay(e.target.value);
	};
	const handleYearChange = (e) => {
		setSelectedYear(e.target.value);
	};

	const handleConfirm = (e) => {
		const date = new Date(
			selectedYear,
			selectedMonth,
			selectedDay
		).toISOString();
		console.log(date);
		props.action(date);

		setSelectedMonth(months[today.getMonth()].id);
		setSelectedDay(today.getDate());
		setSelectedYear(today.getFullYear());
	};

	return (
		<ModalContainer active={show}>
			<Modal>
				<Flex style={{justifyContent: 'space-between'}}>
					<p>{props.message}</p>
					<select value={selectedMonth} onChange={handleMonthChange}>
						{months.map((month, key) => {
							return (
								<option key={key} value={month.id}>
									{month.name}
								</option>
							);
						})}
					</select>
					<select value={selectedDay} onChange={handleDayChange}>
						{[...Array(31).keys()].map((day, key) => {
							return (
								<option key={key} value={day + 1}>
									{day + 1}
								</option>
							);
						})}
					</select>
					<select value={selectedYear} onChange={handleYearChange}>
						{yearOptions()}
					</select>
				</Flex>
				<Flex>
					<button
						type="button"
						className="warning"
						onClick={() => handleConfirm()}>
						{props.confirm}
					</button>
					<button
						type="button"
						onClick={() => {
							setSelectedMonth(months[today.getMonth()].id);
							setSelectedDay(today.getDate());
							setSelectedYear(today.getFullYear());
							props.cancel();
						}}>
						Cancel
					</button>
				</Flex>
			</Modal>
		</ModalContainer>
	);
};

export default DateSelectDialog;
