const userStorage = localStorage.getItem('user')
	? JSON.parse(localStorage.getItem('user'))
	: null;

export const initialState = {
	isLoading: true,
	isError: false,
	user: userStorage || null,
};

export const AuthReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_USER_DATA_INIT':
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case 'FETCH_USER_DATA_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				user: action.payload.user,
			};
		case 'FETCH_USER_DATA_FAILURE':
			return {...state, isLoading: false, isError: true};
		case 'RESET_USER_DATA':
			return {...state, user: null};
		default:
			throw new Error();
	}
};
