import * as React from 'react';
import {Auth, Hub} from 'aws-amplify';
import {useHistory} from 'react-router-dom';
import {initialState, AuthReducer} from './reducer';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
	return context;
}

export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a AuthProvider');
	}

	return context;
}

export function AuthProvider({children}) {
	const [state, dispatch] = React.useReducer(AuthReducer, initialState);
	const [triggerFetch, setTriggerFetch] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => {
		let isMounted = true;

		const fetchUserData = async () => {
			const session = Auth.currentSession();
			session.then((res) => res).catch((err) => console.log(err));
			if (isMounted) {
				dispatch({type: 'FETCH_USER_DATA_INIT'});
			}
			try {
				if (isMounted) {
					const data = await Auth.currentAuthenticatedUser();
					if (data) {
						localStorage.setItem('user', JSON.stringify(data));
						dispatch({
							type: 'FETCH_USER_DATA_SUCCESS',
							payload: {user: data},
						});
					} else {
						history.push('/');
					}
				}
			} catch (error) {
				if (isMounted) {
					dispatch({type: 'FETCH_USER_DATA_FAILURE'});
				}
			}
		};

		const HubListener = () => {
			Hub.listen('auth', (data) => {
				const {payload} = data;
				onAuthEvent(payload);
			});
		};

		const onAuthEvent = (payload) => {
			switch (payload.event) {
				case 'signIn':
					if (isMounted) {
						setTriggerFetch(true);
						console.log('signed in');
					}
					break;
				default:
					return;
			}
		};
		HubListener();
		fetchUserData();

		return () => {
			Hub.remove('auth');
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerFetch]);

	return (
		<AuthStateContext.Provider value={state}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
}

// export const AuthProvider = ({children}) => {
// 	const [user, dispatch] = React.useReducer(AuthReducer, initialState);

// 	return (
// 		<AuthStateContext.Provider value={user}>
// 			<AuthDispatchContext.Provider value={dispatch}>
// 				{children}
// 			</AuthDispatchContext.Provider>
// 		</AuthStateContext.Provider>
// 	);
// };
