import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {Button, MenuItem} from '@material-ui/core';

import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import DatePicker from '../Components/DatePicker';
import {toDateString, fromDateString} from '../lib/helpers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLandmark} from '@fortawesome/free-solid-svg-icons';
import {checkToken} from '../Context/actions';

const ServiceForm = ({
	user,
	client,
	service,
	editMode,
	serviceForm,
	setActive,
	setActivityList,
}) => {
	// console.log(service, client);
	const serviceLength = service && service.service.length > 0;
	const [editService, setEditService] = React.useState(serviceLength);
	const [serviceTypes, setServiceTypes] = React.useState();
	const [incentives, setIncentives] = React.useState();
	const {control, handleSubmit, watch} = serviceForm;

	async function getServices() {
		const response = await API.graphql({
			query: queries.listSpecialServiceTypes,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	// async function getIncentives() {
	// 	const response = await API.graphql({
	// 		query: queries.listIncentives,
	// 		authMode: 'AMAZON_COGNITO_USER_POOLS',
	// 	});
	// 	return response;
	// }

	React.useEffect(() => {
		const services = getServices();
		services
			.then((res) =>
				setServiceTypes(res.data.listSpecialServiceTypes.items)
			)
			.catch((err) => checkToken());
		// const incentives = getIncentives();
		// incentives.then((res) => setIncentives(res.data.listIncentives.items));
	}, []);

	async function createService(newService) {
		const response = await API.graphql({
			query: mutations.createSpecialService,
			variables: {input: newService},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			serviceForm.reset();
			setActive('client');
			setActivityList(false);
		}

		return response;
	}
	async function updateService(existingService) {
		console.log(existingService);
		const response = await API.graphql({
			query: mutations.updateSpecialService,
			variables: {input: existingService},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			serviceForm.reset();
			setActive('client');
			setActivityList(false);
		}

		return response;
	}

	const onSubmit = (data) => {
		let serviceValues = data;
		serviceValues.clientID = client.id;

		serviceValues.date = toDateString(
			data.servicemonth,
			data.serviceday,
			data.serviceyear
		);
		serviceValues.serviceName = serviceTypes.filter(
			(item) => item.id === serviceValues.typeID
		)[0].name;
		let parsed = Object.keys(serviceValues)
			.filter((k) => serviceValues[k] != null)
			.reduce((a, k) => ({...a, [k]: serviceValues[k]}), {});

		delete parsed.servicemonth;
		delete parsed.serviceday;
		delete parsed.serviceyear;
		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		console.log(parsed);
		createService(parsed);
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DatePicker
					form={serviceForm}
					groupLabel="Date"
					initialValue={
						(service && fromDateString(service.date)) || {
							month: 'January',
						}
					}
					nameObj={{
						month: 'servicemonth',
						day: 'serviceday',
						year: 'serviceyear',
					}}
				/>
				<DetailItem>
					<FontAwesomeIcon icon={faLandmark} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="item-field"
							name="typeID"
							label="Service Type"
							defaultValue={(service && service.typeID) || ''}
							variant="outlined">
							{serviceTypes &&
								serviceTypes.map((type, key) => (
									<MenuItem key={key} value={type.id}>
										{type.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default ServiceForm;
