/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Notify from '../Components/Notify';

import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import ReferrersForm from '../Forms/ReferrersForm';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const ReferrersDashboard = () => {
	const [referrers, setReferrers] = React.useState();
	const [selectedReferrer, setSelectedReferrer] = React.useState();
	const [refreshReferrers, setRefreshReferrers] = React.useState(false);
	const [activeView, setActiveView] = React.useState('referrers');
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const referrersForm = useForm({
		defaultValues: selectedReferrer,
	});

	async function getReferrers() {
		const response = await API.graphql({
			query: queries.listReferringAgencys,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	function deleteReferrer(referrerId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: referrerId,
			};
			console.log(data);
			const response = await API.graphql({
				query: mutations.deleteReferringAgency,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (response.data.deleteReferringAgency.id === referrerId) {
				setShowNotify(false);
				setRefreshReferrers(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		const referrerData = getReferrers();
		referrerData
			.then((res) => setReferrers(res.data.listReferringAgencys.items))
			.catch((err) => checkToken());
		console.log(referrers);
		setRefreshReferrers(false);
	}, [refreshReferrers]);

	return !referrers ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'referrers' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>Referrering Agencies</h1>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) => setActiveView('addReferrer')}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add referrer</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>

					<ItemList>
						<ItemListTitles>
							<div className="item">
								<p>Name</p>
							</div>
							<div className="item">
								<p>Description</p>
							</div>
							<div className="item">
								<p>Actions</p>
							</div>
						</ItemListTitles>
						{referrers.map((referrer, key) => (
							<ItemRow key={key}>
								<div className="item">
									<p>{referrer.name}</p>
								</div>
								<div className="item">
									<p>{referrer.description}</p>
								</div>

								<div className="action">
									<button
										type="button"
										title="Edit referrer"
										onClick={() => {
											setSelectedReferrer(referrer);
											setActiveView('editReferrer');
										}}>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										type="button"
										title="Delete referrer"
										onClick={() => {
											deleteReferrer(referrer.id, {
												message:
													'Are you sure you want to delete this referrer?',
												confirm: 'Delete',
												cancel: 'Cancel',
												action: {},
											});
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</button>
								</div>
							</ItemRow>
						))}
					</ItemList>
				</>
			) : null}
			{activeView === 'addReferrer' ? (
				<>
					<ColumnHeader>
						<h1>Add Referrering Agency</h1>
						<button
							type="button"
							onClick={(e) => {
								referrersForm.reset();
								setActiveView('referrers');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ReferrersForm
						setActiveView={setActiveView}
						referrersForm={referrersForm}
						setRefreshReferrers={() => {
							setRefreshReferrers(true);
						}}
					/>
				</>
			) : null}
			{activeView === 'editReferrer' ? (
				<>
					<ColumnHeader>
						<h1>Edit Referrering Agency</h1>
						<button
							type="button"
							onClick={(e) => {
								referrersForm.reset();
								setActiveView('referrers');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ReferrersForm
						setActiveView={setActiveView}
						editMode={true}
						referrer={selectedReferrer}
						referrersForm={referrersForm}
						setRefreshReferrers={() => {
							setRefreshReferrers(true);
						}}
					/>
				</>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default ReferrersDashboard;
