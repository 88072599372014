import * as React from 'react';
import {AmplifyAuthenticator, AmplifySignIn} from '@aws-amplify/ui-react';
import {BrowserRouter as Router} from 'react-router-dom';
import LogRocket from 'logrocket';
import {
	unstable_createMuiStrictModeTheme as createMuiTheme,
	ThemeProvider,
} from '@material-ui/core';
import Root from './Root';
import {DashboardContainer} from './Global/StyledComponents';
import {AuthProvider} from './Context/context';
import TopBar from './Layout/TopBar';
import MenuBar from './Layout/MenuBar';

import './Global/global.scss';

window.location.hostname !== 'localhost' &&
	LogRocket.init('ccs-strategies/treehouse');

const theme = createMuiTheme({
	overrides: {
		MuiOutlinedInput: {
			root: {
				backgroundColor: '#ffffff',
			},
		},
	},
	typography: {
		button: {
			color: '#ffffff',
		},
	},
	palette: {
		primary: {
			main: '#1976d2',
			light: '#06a6f0',
			dark: '#0eacf5',
		},
	},
});

function App() {
	const [clientsSearch, setClientsSearch] = React.useState();
	return (
		<div className="App">
			<AmplifyAuthenticator>
				<AmplifySignIn
					slot="sign-in"
					formFields={[
						{
							type: 'username',
							label: 'Email address',
							placeholder: 'Enter your email address',
						},
						{
							type: 'password',
							label: 'Password',
							placeholder: 'Enter your password',
						},
					]}>
					<div slot="secondary-footer-content"></div>
				</AmplifySignIn>
				<AuthProvider>
					<Router>
						<ThemeProvider theme={theme}>
							<DashboardContainer>
								<MenuBar />
								<TopBar setClientsSearch={setClientsSearch} />
								<Root
									clientsSearch={clientsSearch}
									setClientsSearch={setClientsSearch}
								/>
							</DashboardContainer>
						</ThemeProvider>
					</Router>
				</AuthProvider>
			</AmplifyAuthenticator>
		</div>
	);
}

export default App;
