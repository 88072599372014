import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import NoteCard from '../Components/NoteCard';
import {
	NotesDashboard,
	ColumnHeader,
	ClientActionButton,
} from '../Global/StyledComponents';

const ClientNotes = ({notes, setActiveView, setSelectedNote}) => {
	return (
		<NotesDashboard>
			<ColumnHeader>
				<h2>Notes</h2>
				<ClientActionButton
					type="button"
					onClick={(e) => {
						console.log(e);
						setActiveView('addNote');
					}}>
					<FontAwesomeIcon icon={faPlus} />
					<span>Add Note</span>
				</ClientActionButton>
			</ColumnHeader>
			<div>
				{notes.map((note, key) => {
					return (
						<NoteCard
							key={key}
							note={note}
							setSelectedNote={setSelectedNote}
							setActiveView={setActiveView}
						/>
					);
				})}
			</div>
		</NotesDashboard>
	);
};

export default ClientNotes;
