export const formatPhoneNumber = (str) => {
	let cleaned = ('' + str).replace(/\D/g, '');
	let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	}

	return null;
};

export const formatDate = (date) => {
	const dateString = `${date.toLocaleString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})}`;

	return dateString;
};

export const formatShortDate = (date) => {
	const dateString = `${date.toLocaleString('en-US', {
		year: '2-digit',
		month: 'numeric',
		day: 'numeric',
	})}`;
	return dateString;
};

export function calculateAge(birthday) {
	// birthday is a date
	var ageDifMs = Date.now() - birthday.getTime();
	var ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const months = [
	{
		id: 0,
		name: 'January',
		length: 31,
	},
	{
		id: 1,
		name: 'February',
		length: 28,
	},
	{
		id: 2,
		name: 'March',
		length: 31,
	},
	{
		id: 3,
		name: 'April',
		length: 30,
	},
	{
		id: 4,
		name: 'May',
		length: 31,
	},
	{
		id: 5,
		name: 'June',
		length: 30,
	},
	{
		id: 6,
		name: 'July',
		length: 31,
	},
	{
		id: 7,
		name: 'August',
		length: 30,
	},
	{
		id: 8,
		name: 'September',
		length: 30,
	},
	{
		id: 9,
		name: 'October',
		length: 31,
	},
	{
		id: 10,
		name: 'November',
		length: 30,
	},
	{
		id: 11,
		name: 'December',
		length: 31,
	},
];

export const toDateString = (month, day, year) => {
	const date = new Date(year, months.find((x) => x.name === month).id, day);
	return date.toISOString();
};

export const fromDateString = (dateString) => {
	const date = new Date(dateString);
	const month = `${date.toLocaleString('en-US', {
		month: 'long',
	})}`;
	const day = `${date.toLocaleString('en-US', {
		day: 'numeric',
	})}`;
	const year = `${date.toLocaleString('en-US', {
		year: 'numeric',
	})}`;
	const dateObj = {month: month, day: day, year: year};
	return dateObj;
};
