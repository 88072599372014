import * as React from 'react';
import styled from 'styled-components';

import {formatShortDate} from '../lib/helpers';
import {DisplayItem, ActivityDeleteButton} from '../Global/StyledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStickyNote} from '@fortawesome/pro-solid-svg-icons';
import {faEdit} from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom';

const SeeAllButton = styled.button`
	padding: 0.5rem 0;
	font-weight: 600;
	color: var(--primary-tint);
`;

const NoteCard = ({note, setSelectedNote, setActiveView, location}) => {
	const [isExpanded, setIsExpanded] = React.useState(false);
	// console.log(location);
	return (
		<DisplayItem>
			<FontAwesomeIcon icon={faStickyNote} />
			<div>
				<span>
					Client: {note.clientFirstname} {note.clientLastname}
				</span>
				<br />
				<span>
					{`Created ${formatShortDate(new Date(note.createdAt))} by ${
						note.createdByUserName
					}`}
				</span>

				<p>
					{isExpanded || note.note.length < 150
						? note.note
						: `${note.note.substring(0, 150)}...`}
				</p>
				{note.note.length > 150 && (
					<SeeAllButton
						type="button"
						onClick={() => setIsExpanded(!isExpanded)}>
						{isExpanded ? `Collapse` : `Expand`}
					</SeeAllButton>
				)}
			</div>
			{location === 'home' ? (
				<ActivityDeleteButton type="button">
					<Link to={`/clients/${note.clientID}`}>
						<FontAwesomeIcon icon={faEdit} />
					</Link>
				</ActivityDeleteButton>
			) : (
				<ActivityDeleteButton
					type="button"
					onClick={() => {
						setSelectedNote(note);
						setActiveView('editNote');
					}}>
					<FontAwesomeIcon icon={faEdit} />
				</ActivityDeleteButton>
			)}
		</DisplayItem>
	);
};

export default NoteCard;
