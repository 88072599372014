// Reports needed:

// Monthly-
// Number of new moms
// Number of new babies
// Number of layette
// Number of moms in class
// Number of videos watched
// Number of volunteer hours
// Number of store visits

// Yearly-
// Ethnicity
// Age
// Level of education
// Number of diapers given out
// Amount of formula given out
// Total number of services provided

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useAuthState} from '../Context/context';

import {
	ContentContainer,
	DashboardTabs,
	ReportSection,
	ColumnHeader,
	ReportContainer,
	ReportYear,
	ReportTable,
	ItemList,
	ItemListTitles,
	ItemRow,
} from '../Global/StyledComponents';
import {ActivityMenu, ActivityMenuItem} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import {months as monthsHelper} from '../lib/helpers';
import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import {checkToken} from '../Context/actions';

const ReportsDashboard = () => {
	const {user} = useAuthState();
	const [activeReport, setActiveReport] = React.useState('clients');

	const [reportData, setReportData] = React.useState({
		year: 2022,
		data: [],
	});
	const reportFunctions = (year) => {
		switch (activeReport) {
			case 'clients':
				getClientsReport(year);
				break;
			case 'children':
				getChildrenReport(year);
				break;
			case 'layettes':
				getLayettesReport(year);
				break;
			case 'helps':
				getHelpsReport(year);
				break;
			case 'classes':
				getClassesReport(year);
				break;
			case 'videos':
				getVideosReport(year);
				break;
			case 'storeVisits':
				getStoreVisitsReport(year);
				break;
			default:
				break;
		}
	};
	const monthsLoop = Array.from(new Array(12), (x, i) => i + 1);

	async function getClients() {
		const response = await API.graphql({
			query: queries.listClients,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	async function getClientsByDate(startDate, endDate) {
		const response = await API.graphql({
			query: queries.countClients,
			variables: {
				filter: {
					createdAt: {
						between: [
							new Date(startDate).toISOString(),
							new Date(endDate).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	async function getClientsByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countClients,
			variables: {
				type: 'client',
				limit: 10000,
				filter: {
					createdAt: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getChildrenByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countChildren,
			variables: {
				type: 'child',
				limit: 10000,
				filter: {
					createdAt: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getLayettesByYear(year, month) {
		const response = await API.graphql({
			query: queries.countLayettes,
			variables: {
				limit: 10000,
				filter: {
					date: {
						eq: String(year),
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getHelpsByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countHelps,
			variables: {
				limit: 20000,
				filter: {
					date: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getClassesByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countClasses,
			variables: {
				limit: 10000,
				filter: {
					completion_date: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getVideosByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countVideos,
			variables: {
				limit: 10000,
				filter: {
					date: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	async function getStoreVisitsByMonth(year, month) {
		const response = await API.graphql({
			query: queries.countStoreVisits,
			variables: {
				limit: 20000,
				filter: {
					date: {
						between: [
							new Date(`${year}-${month}-01`).toISOString(),
							new Date(`${year}-${month}-31`).toISOString(),
						],
					},
				},
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	async function getClientsReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getClientsByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listClients.items.length;
			return {
				[month]: value,
			};
		});
		console.log('clients', data);
		setReportData({year: year, data: data});
		return data;
	}
	async function getChildrenReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getChildrenByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listChilds.items.length;
			return {
				[month]: value,
			};
		});
		console.log('children:', data);
		setReportData({year: year, data: data});
		return data;
	}
	async function getLayettesReport(year) {
		// const promises = monthsLoop.map(async (month) => {
		// 	const response = await getLayettesByYear(year);
		// 	return response;
		// });
		// const results = await Promise.all(promises);
		const response = await getLayettesByYear(year);
		const data = response.data.listLayettes.items.length;
		// const data = results.map((result, key) => {
		// 	const month = monthsHelper.find((x) => x.id === key).name;
		// 	const value = result.data.listLayettes.items.length;
		// 	return {
		// 		[month]: value,
		// 	};
		// });
		console.log('layette:', data);
		setReportData({year: year, data: [{total: data}]});
		return data;
	}
	async function getHelpsReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getHelpsByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listHelps.items.length;
			return {
				[month]: value,
			};
		});
		console.log('helps:', data);
		setReportData({year: year, data: data});
		return data;
	}
	async function getClassesReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getClassesByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listClasss.items.length;
			return {
				[month]: value,
			};
		});
		console.log('classes:', data);
		setReportData({year: year, data: data});
		return data;
	}
	async function getVideosReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getVideosByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listVideos.items.length;
			return {
				[month]: value,
			};
		});
		console.log('videos:', data);
		setReportData({year: year, data: data});
		return data;
	}
	async function getStoreVisitsReport(year) {
		const promises = monthsLoop.map(async (month) => {
			const response = await getStoreVisitsByMonth(year, month);
			// console.log(response);
			return response;
		});
		const results = await Promise.all(promises);
		const data = results.map((result, key) => {
			const month = monthsHelper.find((x) => x.id === key).name;
			const value = result.data.listStoreVisits.items.length;
			return {
				[month]: value,
			};
		});
		console.log('videos:', data);
		setReportData({year: year, data: data});
		return data;
	}
	//create array of all years from 2021 to current year
	const yearsLoop = Array.from(
		{length: new Date().getFullYear() - 2021 + 1},
		(v, k) => k + 2021
	);
	React.useEffect(() => {
		getClientsReport(new Date().getFullYear());

		// const helpsThisMonth = getHelpsByDate('2020-01-01', '2020-12-31');
		// helpsThisMonth
		// 	.then((res) => console.log(res))
		// 	.catch((err) => console.log(err));
	}, []);
	React.useEffect(() => {
		console.log(reportData.year);
	}, [reportData]);
	return !user ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<h1>Reports</h1>
			<DashboardTabs active={activeReport}>
				<button
					type="button"
					onClick={() => {
						getClientsReport(reportData.year);
						setActiveReport('clients');
					}}>
					Clients
				</button>
				<button
					type="button"
					onClick={() => {
						getChildrenReport(reportData.year);
						setActiveReport('children');
					}}>
					Children
				</button>
				<button
					type="button"
					onClick={() => {
						getLayettesReport(reportData.year);
						setActiveReport('layettes');
					}}>
					Layettes
				</button>
				<button
					type="button"
					onClick={() => {
						getHelpsReport(reportData.year);
						setActiveReport('helps');
					}}>
					Helps
				</button>
				<button
					type="button"
					onClick={() => {
						getClassesReport(reportData.year);
						setActiveReport('classes');
					}}>
					Classes
				</button>
				<button
					type="button"
					onClick={() => {
						getVideosReport(reportData.year);
						setActiveReport('videos');
					}}>
					Videos
				</button>
				<button
					type="button"
					onClick={() => {
						getStoreVisitsReport(reportData.year);
						setActiveReport('storeVisits');
					}}>
					Store Visits
				</button>
			</DashboardTabs>

			<>
				<ReportSection>
					<ColumnHeader>
						<h2>
							{activeReport.charAt(0).toUpperCase() +
								activeReport.slice(1)}{' '}
							Report
						</h2>
					</ColumnHeader>
					<ReportContainer>
						<ActivityMenu>
							{yearsLoop.map((year) => (
								<ActivityMenuItem
									active={reportData.year === year}
									onClick={() => reportFunctions(year)}>
									{year}
								</ActivityMenuItem>
							))}
						</ActivityMenu>
						<ReportTable>
							<ItemList>
								<ItemListTitles>
									<div className="item">
										<p>{reportData.year}</p>
									</div>
									<div className="item">
										<p>Total</p>
									</div>
								</ItemListTitles>
							</ItemList>
							{console.log(reportData)}
							{activeReport !== 'layettes' &&
								reportData.data.map((item, index) => (
									<ItemRow
										key={index}
										style={{
											justifyContent: 'space-between',
										}}>
										<div className="item">
											<p>{Object.keys(item)[0]}</p>
										</div>
										<div className="item">
											<p>{Object.values(item)[0]}</p>
										</div>
									</ItemRow>
								))}
							{activeReport === 'layettes' && (
								<ItemRow
									style={{
										justifyContent: 'space-between',
									}}>
									<div className="item">
										<p>{`${reportData.year} Total`}</p>
									</div>
									<div className="item">
										<p>{reportData.data[0].total}</p>
									</div>
								</ItemRow>
							)}
						</ReportTable>
					</ReportContainer>
				</ReportSection>
			</>
		</ContentContainer>
	);
};

export default ReportsDashboard;
