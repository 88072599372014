import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ActivityMenu, ActivityMenuItem} from '../Global/StyledComponents';
import {
	faShoppingCart,
	faGraduationCap,
	faVideo,
	faHandshake,
	faGift,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import {faSparkles} from '@fortawesome/pro-regular-svg-icons';

const ActivitiesFilters = ({types, currentActivity, setCurrentActivity}) => {
	console.log(types);

	const count = (activityName) => {
		return types.filter((type) => type === activityName).length;
	};
	return (
		<ActivityMenu>
			<ActivityMenuItem
				active={currentActivity.name === 'allActivities'}
				onClick={() =>
					setCurrentActivity({
						name: 'allActivities',
						title: 'All Activities',
						activeView: null,
					})
				}>
				<FontAwesomeIcon icon={faUser} />
				<span>All Activities ({types.length})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'storeVisit'}
				onClick={() => {
					setCurrentActivity({
						name: 'storeVisit',
						title: 'Store Visits',
						activeView: null,
					});
				}}>
				<FontAwesomeIcon icon={faShoppingCart} />
				<span>Store Visits ({count('storeVisit')})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'help'}
				onClick={() => {
					setCurrentActivity({
						name: 'help',
						title: 'Emergency Helps',
						activeView: 'addHelp',
					});
				}}>
				<FontAwesomeIcon icon={faHandshake} />
				<span>Emergency Helps ({count('help')})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'class'}
				onClick={() => {
					setCurrentActivity({
						name: 'class',
						title: 'Classes',
						activeView: 'addClass',
					});
				}}>
				<FontAwesomeIcon icon={faGraduationCap} />
				<span>Classes ({count('class')})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'video'}
				onClick={() => {
					setCurrentActivity({
						name: 'video',
						title: 'Videos',
						activeView: 'addVideo',
					});
				}}>
				<FontAwesomeIcon icon={faVideo} />
				<span>Videos ({count('video')})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'service'}
				onClick={() => {
					setCurrentActivity({
						name: 'service',
						title: 'Special Services',
						activeView: 'addService',
					});
				}}>
				<FontAwesomeIcon icon={faSparkles} />
				<span>Special Services ({count('service')})</span>
			</ActivityMenuItem>
			<ActivityMenuItem
				active={currentActivity.name === 'newLayette'}
				onClick={() => {
					setCurrentActivity({
						name: 'newLayette',
						title: 'Layette',
						activeView: null,
					});
				}}>
				<FontAwesomeIcon icon={faGift} />
				<span>Layette ({count('newLayette')})</span>
			</ActivityMenuItem>
		</ActivityMenu>
	);
};

export default ActivitiesFilters;
