import * as React from 'react';
import algoliasearch from 'algoliasearch';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	ClientForm,
	DetailItem,
	FieldWrapper,
	ClientSuggestions,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useHistory, Link} from 'react-router-dom';

import {toDateString} from '../lib/helpers';
import {Controller} from 'react-hook-form';
import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import {
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	MenuItem,
} from '@material-ui/core';
import DatePicker from '../Components/DatePicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPhone,
	faEnvelope,
	faUser,
	faBuilding,
	faInbox,
} from '@fortawesome/free-solid-svg-icons';
import {
	faAddressBook,
	faCreditCardBlank,
	faRingsWedding,
	faUniversity,
} from '@fortawesome/pro-regular-svg-icons';
import {faUserFriends} from '@fortawesome/pro-duotone-svg-icons';

const searchClient = algoliasearch(
	'5NXAX43H18',
	'3319ec918cccc0d77fac6e378c7b6cca'
);
const searchIndex = searchClient.initIndex('Treehouse-client-prod');

const CreateClientForm = ({client, editMode, form, setActive}) => {
	console.log(editMode);
	const [referrers, setReferrers] = React.useState([]);
	const [clientSuggestions, setClientSuggestions] = React.useState([]);
	let history = useHistory();

	async function getReferrers() {
		const response = await API.graphql({
			query: queries.listReferringAgencys,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response.data.listReferringAgencys.items;
	}

	async function createClient(newClient) {
		const response = await API.graphql({
			query: mutations.createClient,
			variables: {input: newClient},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			history.push(`/clients/${response.data.createClient.id}`);
		}

		return response;
	}

	async function updateClient(attrs) {
		const response = await API.graphql({
			query: mutations.updateClient,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setActive('client');
		}
		return response;
	}

	const {control, handleSubmit, watch} = form;

	// const watchFields = watch(['firstName', 'lastName']);

	React.useEffect(() => {
		if (editMode) return;
		const subscription = watch((value, {name, type}) => {
			console.log(value, name, type);
			if (name === 'firstName' || 'lastName') {
				let term = '';
				if (value.firstName) term += value.firstName;
				if (value.firstName && value.lastName) term += ' ';
				if (value.lastName) term += value.lastName;
				console.log(term.length);
				if (term.length > 0) {
					searchIndex
						.search(term, {
							hitsPerPage: 3,
						})
						.then(({hits}) => {
							setClientSuggestions(hits);
						});
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const onSubmit = (data) => {
		let clientValues = data;
		// console.log(clientValues);
		clientValues.type = 'client';
		if (clientValues.birthyear) {
			clientValues.birthdate = toDateString(
				data.birthmonth,
				data.birthday,
				data.birthyear
			);
		}
		let parsed = Object.keys(clientValues)
			.filter((k) => clientValues[k] != null)
			.reduce((a, k) => ({...a, [k]: clientValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;
		delete parsed.birthObj;
		delete parsed.birthmonth;
		delete parsed.birthday;
		delete parsed.birthyear;
		delete parsed.children;
		delete parsed.classes;
		delete parsed.incentives;
		delete parsed.specialServices;
		delete parsed.activities;
		delete parsed.helps;
		delete parsed.newLayette;
		delete parsed.storeVisits;
		delete parsed.videos;
		delete parsed.notes;

		if (!editMode) {
			createClient(parsed);
		} else {
			console.log(parsed);
			updateClient(parsed);
		}
	};

	React.useEffect(() => {
		const referrers = getReferrers();
		referrers.then((res) => setReferrers(res));
	}, []);

	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ClientForm onSubmit={handleSubmit(onSubmit)}>
			{/* Profile photo? */}
			<DetailItem>
				<FieldWrapper>
					<FormControlLabel
						label="IRIS Referral"
						control={
							<Controller
								name="iris"
								control={control}
								defaultValue={client.iris ? client.iris : true}
								render={({field}) => (
									<Checkbox
										checked={field.value}
										{...field}
									/>
								)}
							/>
						}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FontAwesomeIcon icon={faInbox} size="lg" />
				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="intakeStatus-field"
						name="intakeStatus"
						label="Intake Status"
						defaultValue={
							client.intakeStatus !== null
								? client.intakeStatus
								: 'pending'
						}
						variant="outlined">
						<MenuItem value="pending">Pending</MenuItem>
						<MenuItem value="complete">Complete</MenuItem>
					</ReactHookFormSelect>
				</FieldWrapper>
				<FontAwesomeIcon icon={faBuilding} size="lg" />
				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="referringAgency-field"
						name="referringAgency"
						label="Referring Agency"
						defaultValue={
							client.referringAgency !== null
								? client.referringAgency
								: 'none'
						}
						variant="outlined">
						{referrers &&
							referrers.map((referrer, key) => (
								<MenuItem key={key} value={referrer.id}>
									{referrer.name}
								</MenuItem>
							))}
					</ReactHookFormSelect>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<FontAwesomeIcon icon={faUser} size="lg" />

					<Controller
						name="firstName"
						control={control}
						defaultValue={client.firstName || ''}
						render={({field}) => (
							<TextField
								label="First Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
					<Controller
						// id="outlined-basic"
						// className="half"
						control={control}
						name="lastName"
						defaultValue={client.lastName || ''}
						render={({field}) => (
							<TextField
								label="Last Name"
								variant="outlined"
								autoComplete="foo"
								type="text"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			{clientSuggestions.length > 0 && !editMode ? (
				<ClientSuggestions>
					<p className="label">
						Are you looking for one of these existing clients?
					</p>
					{clientSuggestions.map((client, key) => {
						return (
							<Link
								to={`clients/${client.id}`}
								key={
									key
								}>{`${client.firstName} ${client.lastName}`}</Link>
						);
					})}
				</ClientSuggestions>
			) : null}

			<section>
				<DetailItem>
					<FontAwesomeIcon icon={faPhone} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="phone"
							defaultValue={client.phone || ''}
							render={({field}) => (
								<TextField
									label="Phone"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faEnvelope} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="email"
							defaultValue={client.email || ''}
							render={({field}) => (
								<TextField
									label="Email"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>

				<DatePicker
					form={form}
					groupLabel="Date of birth"
					initialValue={client.birthObj}
					nameObj={{
						month: 'birthmonth',
						day: 'birthday',
						year: 'birthyear',
					}}
				/>
				<DetailItem>
					<FontAwesomeIcon icon={faAddressBook} size="lg" />
					<FieldWrapper className="wrap">
						<Controller
							// id="outlined-basic"
							control={control}
							name="address"
							defaultValue={client.address || ''}
							render={({field}) => (
								<TextField
									className="full"
									label="Address"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="city"
							defaultValue={client.city || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="City"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="state"
							defaultValue={client.state || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="State"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="zip"
							defaultValue={client.zip || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="Zip"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faPhone} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="preferredlanguage-field"
							name="preferredLanguage"
							label="Preferred Language"
							defaultValue={client.preferredLanguage || 'English'}
							variant="outlined">
							<MenuItem value="English">English</MenuItem>
							<MenuItem value="Spanish">Spanish</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faRingsWedding} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="maritalStatus-field"
							name="maritalStatus"
							label="Marital Status"
							defaultValue={client.maritalStatus || 'Unknown'}
							variant="outlined">
							<MenuItem value="Unknown">Unknown</MenuItem>
							<MenuItem value="Married">Married</MenuItem>
							<MenuItem value="Unmarried">Unmarried</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
					<FontAwesomeIcon icon={faUserFriends} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="ethnicity-field"
							name="ethnicity"
							label="Ethnicity"
							defaultValue={client.ethnicity || ''}
							variant="outlined">
							<MenuItem value="African American">
								African American
							</MenuItem>
							<MenuItem value="Asian">Asian</MenuItem>
							<MenuItem value="Bi-Racial">Bi-Racial</MenuItem>
							<MenuItem value="Caucasian">Caucasian</MenuItem>
							<MenuItem value="Hispanic">Hispanic</MenuItem>
							<MenuItem value="Native American">
								Native American
							</MenuItem>
							<MenuItem value="South Pacific Islander">
								South Pacific Islander
							</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faUniversity} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="education-field"
							name="education"
							label="Education"
							defaultValue={client.education || 'High School/GED'}
							variant="outlined">
							<MenuItem value="Elementary School Grad">
								Elementary School Grad
							</MenuItem>
							<MenuItem value="Middle School Grad">
								Middle School Grad
							</MenuItem>
							<MenuItem value="Attending High School">
								Attending High School
							</MenuItem>
							<MenuItem value="High School Diploma/GED">
								High School Diploma/GED
							</MenuItem>
							<MenuItem value="College/Trade School Grad">
								College/Trade School Grad
							</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
					<FontAwesomeIcon icon={faCreditCardBlank} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="wicStatus-field"
							name="wicStatus"
							label="WIC Status"
							defaultValue={client.wicStatus || 'No'}
							variant="outlined">
							<MenuItem value="Yes">Yes</MenuItem>
							<MenuItem value="No">No</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				{/* <DetailItem>
					<FontAwesomeIcon icon={faStickyNote} size="lg" />
					<FieldWrapper>
						<TextField
							multiline={true}
							rows="5"
							label="Notes"
							type="textarea"
							variant="outlined"
							name="notes"
							value={formik.values.notes || ''}
							onChange={formik.handleChange}
						/>
					</FieldWrapper>
				</DetailItem> */}

				{/* 
					{
						"videos": null,
						"layette": null,
						"helps": null,
						"children": null,
						"incentives": null,
						"description": null,
						"notes": null,
						"createdAt": "2021-04-24T17:16:33.216Z",
						"updatedAt": "2021-04-24T17:16:33.216Z",
						"modified": "April 24, 2021"
					} 
				*/}
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disableElevation>
					Save
				</Button>
			</section>
		</ClientForm>
	);
};

export default CreateClientForm;
