import * as React from 'react';

import {ItemRow} from '../Global/StyledComponents';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit} from '@fortawesome/pro-regular-svg-icons';

const UserRow = ({user, saveFunction, deleteFunction}) => {
	const [editMode, setEditMode] = React.useState(false);
	const [updatedRole, setUpdatedRole] = React.useState(user.role);
	function saveAction() {
		// console.log('Save Action');
		saveFunction({username: user.username, groupname: updatedRole});
		setEditMode(!editMode);
	}
	return (
		<ItemRow>
			<div className="item">
				<p>{user.name}</p>
			</div>
			<div className="item">
				<p>{user.email}</p>
			</div>
			<div className="item">
				<p>{user.status}</p>
			</div>
			<div className="item">
				{editMode ? (
					<select
						defaultValue={`${user.role.toLowerCase()}s`}
						onChange={(e) => setUpdatedRole(e.target.value)}>
						<option value="admins">Admin</option>
						<option value="users">User</option>
					</select>
				) : (
					<p>
						{user.email !== 'ccs.strategies@gmail.com'
							? user.role
							: 'System Admin'}
					</p>
				)}
			</div>
			{user.email !== 'ccs.strategies@gmail.com' ? (
				<div className="action">
					<button
						type="button"
						title="Save Changes"
						className="save-button"
						style={
							!editMode ? {display: 'none'} : {display: 'block'}
						}
						onClick={() => saveAction()}>
						Save
					</button>
					<button
						type="button"
						title="Edit User"
						className="toggle-button"
						onClick={() => {
							if (editMode) {
								setUpdatedRole(user.role);
							}
							setEditMode(!editMode);
						}}>
						{editMode ? (
							`Cancel`
						) : (
							<FontAwesomeIcon icon={faEdit} />
						)}
					</button>
					<button
						type="button"
						title="Delete User"
						style={
							editMode ? {display: 'none'} : {display: 'block'}
						}
						className="delete-button"
						onClick={() => {
							deleteFunction(user.username, {
								message:
									'Are you sure you want to delete this user?',
								confirm: 'Delete',
								cancel: 'Cancel',
								action: {},
							});
						}}>
						<FontAwesomeIcon icon={faTrashAlt} />
					</button>
				</div>
			) : null}
		</ItemRow>
	);
};

export default UserRow;
