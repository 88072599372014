import * as React from 'react';
import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

import {Controller} from 'react-hook-form';
import {Form, DetailItem, FieldWrapper} from '../Global/StyledComponents';
import {TextField, Button} from '@material-ui/core';

const PillarsForm = ({
	setActiveView,
	pillar,
	pillarsForm,
	setRefreshPillars,
	editMode,
}) => {
	async function createPillar(pillar) {
		console.log([pillar]);
		const response = await API.graphql({
			query: mutations.createPillar,
			variables: {input: pillar},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}
	async function updatePillar(pillar) {
		const response = await API.graphql({
			query: mutations.updatePillar,
			variables: {input: pillar},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		return response;
	}

	const {control, handleSubmit} = pillarsForm;

	const onSubmit = (data) => {
		console.log(data);
		let pillarValues = data;

		let parsed = Object.keys(pillarValues)
			.filter((k) => pillarValues[k] != null)
			.reduce((a, k) => ({...a, [k]: pillarValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		if (!editMode) {
			createPillar(parsed);
			setRefreshPillars();
			setActiveView('pillars');
			pillarsForm.reset();
		} else {
			parsed.id = pillar.id;
			updatePillar(parsed);
			setRefreshPillars();
			setActiveView('pillars');
			pillarsForm.reset();
		}
	};
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="name"
						control={control}
						defaultValue={(pillar && pillar.name) || ''}
						render={({field}) => (
							<TextField
								label="Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<Controller
						name="description"
						control={control}
						render={({field}) => (
							<TextField
								multiline
								label="Description (optional)"
								rows={8}
								variant="outlined"
								defaultValue={
									(pillar && pillar.description) || ''
								}
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default PillarsForm;
