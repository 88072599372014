/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      birthdate
      address
      city
      state
      zip
      email
      phone
      maritalStatus
      ethnicity
      preferredLanguage
      education
      wicStatus
      iris
      intakeStatus
      referringAgency
      classes {
        items {
          id
          clientID
          name
          description
          completion_date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          clientID
          video_id
          date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      layette
      newLayette {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      helps {
        items {
          id
          clientID
          date
          helpName
          helpID
          createdAt
          updatedAt
        }
        nextToken
      }
      children {
        items {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        nextToken
      }
      specialServices {
        items {
          id
          clientID
          date
          serviceName
          typeID
          createdAt
          updatedAt
        }
        nextToken
      }
      storeVisits {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      notes {
        items {
          id
          clientID
          clientFirstname
          clientLastname
          type
          createdByUser
          createdByUserName
          date
          createdAt
          note
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      birthdate
      address
      city
      state
      zip
      email
      phone
      maritalStatus
      ethnicity
      preferredLanguage
      education
      wicStatus
      iris
      intakeStatus
      referringAgency
      classes {
        items {
          id
          clientID
          name
          description
          completion_date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          clientID
          video_id
          date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      layette
      newLayette {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      helps {
        items {
          id
          clientID
          date
          helpName
          helpID
          createdAt
          updatedAt
        }
        nextToken
      }
      children {
        items {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        nextToken
      }
      specialServices {
        items {
          id
          clientID
          date
          serviceName
          typeID
          createdAt
          updatedAt
        }
        nextToken
      }
      storeVisits {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      notes {
        items {
          id
          clientID
          clientFirstname
          clientLastname
          type
          createdByUser
          createdByUserName
          date
          createdAt
          note
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      birthdate
      address
      city
      state
      zip
      email
      phone
      maritalStatus
      ethnicity
      preferredLanguage
      education
      wicStatus
      iris
      intakeStatus
      referringAgency
      classes {
        items {
          id
          clientID
          name
          description
          completion_date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          clientID
          video_id
          date
          pillarID
          incentiveID
          createdAt
          updatedAt
        }
        nextToken
      }
      layette
      newLayette {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      helps {
        items {
          id
          clientID
          date
          helpName
          helpID
          createdAt
          updatedAt
        }
        nextToken
      }
      children {
        items {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        nextToken
      }
      specialServices {
        items {
          id
          clientID
          date
          serviceName
          typeID
          createdAt
          updatedAt
        }
        nextToken
      }
      storeVisits {
        items {
          id
          clientID
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      notes {
        items {
          id
          clientID
          clientFirstname
          clientLastname
          type
          createdByUser
          createdByUserName
          date
          createdAt
          note
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClientNote = /* GraphQL */ `
  mutation CreateClientNote(
    $input: CreateClientNoteInput!
    $condition: ModelClientNoteConditionInput
  ) {
    createClientNote(input: $input, condition: $condition) {
      id
      clientID
      clientFirstname
      clientLastname
      type
      createdByUser
      createdByUserName
      date
      createdAt
      note
      updatedAt
    }
  }
`;
export const updateClientNote = /* GraphQL */ `
  mutation UpdateClientNote(
    $input: UpdateClientNoteInput!
    $condition: ModelClientNoteConditionInput
  ) {
    updateClientNote(input: $input, condition: $condition) {
      id
      clientID
      clientFirstname
      clientLastname
      type
      createdByUser
      createdByUserName
      date
      createdAt
      note
      updatedAt
    }
  }
`;
export const deleteClientNote = /* GraphQL */ `
  mutation DeleteClientNote(
    $input: DeleteClientNoteInput!
    $condition: ModelClientNoteConditionInput
  ) {
    deleteClientNote(input: $input, condition: $condition) {
      id
      clientID
      clientFirstname
      clientLastname
      type
      createdByUser
      createdByUserName
      date
      createdAt
      note
      updatedAt
    }
  }
`;
export const createChild = /* GraphQL */ `
  mutation CreateChild(
    $input: CreateChildInput!
    $condition: ModelChildConditionInput
  ) {
    createChild(input: $input, condition: $condition) {
      id
      clientID
      pregnancy
      dueDate
      firstName
      lastName
      birthdate
      gender
      vaccination
      feedingType
      daycare {
        id
        date
        child {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChild = /* GraphQL */ `
  mutation UpdateChild(
    $input: UpdateChildInput!
    $condition: ModelChildConditionInput
  ) {
    updateChild(input: $input, condition: $condition) {
      id
      clientID
      pregnancy
      dueDate
      firstName
      lastName
      birthdate
      gender
      vaccination
      feedingType
      daycare {
        id
        date
        child {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChild = /* GraphQL */ `
  mutation DeleteChild(
    $input: DeleteChildInput!
    $condition: ModelChildConditionInput
  ) {
    deleteChild(input: $input, condition: $condition) {
      id
      clientID
      pregnancy
      dueDate
      firstName
      lastName
      birthdate
      gender
      vaccination
      feedingType
      daycare {
        id
        date
        child {
          id
          clientID
          pregnancy
          dueDate
          firstName
          lastName
          birthdate
          gender
          vaccination
          feedingType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDaycareVisit = /* GraphQL */ `
  mutation CreateDaycareVisit(
    $input: CreateDaycareVisitInput!
    $condition: ModelDaycareVisitConditionInput
  ) {
    createDaycareVisit(input: $input, condition: $condition) {
      id
      date
      child {
        id
        clientID
        pregnancy
        dueDate
        firstName
        lastName
        birthdate
        gender
        vaccination
        feedingType
        daycare {
          id
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDaycareVisit = /* GraphQL */ `
  mutation UpdateDaycareVisit(
    $input: UpdateDaycareVisitInput!
    $condition: ModelDaycareVisitConditionInput
  ) {
    updateDaycareVisit(input: $input, condition: $condition) {
      id
      date
      child {
        id
        clientID
        pregnancy
        dueDate
        firstName
        lastName
        birthdate
        gender
        vaccination
        feedingType
        daycare {
          id
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDaycareVisit = /* GraphQL */ `
  mutation DeleteDaycareVisit(
    $input: DeleteDaycareVisitInput!
    $condition: ModelDaycareVisitConditionInput
  ) {
    deleteDaycareVisit(input: $input, condition: $condition) {
      id
      date
      child {
        id
        clientID
        pregnancy
        dueDate
        firstName
        lastName
        birthdate
        gender
        vaccination
        feedingType
        daycare {
          id
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReferringAgency = /* GraphQL */ `
  mutation CreateReferringAgency(
    $input: CreateReferringAgencyInput!
    $condition: ModelReferringAgencyConditionInput
  ) {
    createReferringAgency(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateReferringAgency = /* GraphQL */ `
  mutation UpdateReferringAgency(
    $input: UpdateReferringAgencyInput!
    $condition: ModelReferringAgencyConditionInput
  ) {
    updateReferringAgency(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferringAgency = /* GraphQL */ `
  mutation DeleteReferringAgency(
    $input: DeleteReferringAgencyInput!
    $condition: ModelReferringAgencyConditionInput
  ) {
    deleteReferringAgency(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createPillar = /* GraphQL */ `
  mutation CreatePillar(
    $input: CreatePillarInput!
    $condition: ModelPillarConditionInput
  ) {
    createPillar(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updatePillar = /* GraphQL */ `
  mutation UpdatePillar(
    $input: UpdatePillarInput!
    $condition: ModelPillarConditionInput
  ) {
    updatePillar(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deletePillar = /* GraphQL */ `
  mutation DeletePillar(
    $input: DeletePillarInput!
    $condition: ModelPillarConditionInput
  ) {
    deletePillar(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createClass = /* GraphQL */ `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      id
      clientID
      name
      description
      completion_date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClass = /* GraphQL */ `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      id
      clientID
      name
      description
      completion_date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClass = /* GraphQL */ `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      id
      clientID
      name
      description
      completion_date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      clientID
      video_id
      date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      clientID
      video_id
      date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      clientID
      video_id
      date
      pillarID
      pillar {
        id
        name
        description
        createdAt
        updatedAt
      }
      incentiveID
      incentive {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHelp = /* GraphQL */ `
  mutation CreateHelp(
    $input: CreateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    createHelp(input: $input, condition: $condition) {
      id
      clientID
      date
      helpName
      helpID
      help {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHelp = /* GraphQL */ `
  mutation UpdateHelp(
    $input: UpdateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    updateHelp(input: $input, condition: $condition) {
      id
      clientID
      date
      helpName
      helpID
      help {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelp = /* GraphQL */ `
  mutation DeleteHelp(
    $input: DeleteHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    deleteHelp(input: $input, condition: $condition) {
      id
      clientID
      date
      helpName
      helpID
      help {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHelpType = /* GraphQL */ `
  mutation CreateHelpType(
    $input: CreateHelpTypeInput!
    $condition: ModelHelpTypeConditionInput
  ) {
    createHelpType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateHelpType = /* GraphQL */ `
  mutation UpdateHelpType(
    $input: UpdateHelpTypeInput!
    $condition: ModelHelpTypeConditionInput
  ) {
    updateHelpType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelpType = /* GraphQL */ `
  mutation DeleteHelpType(
    $input: DeleteHelpTypeInput!
    $condition: ModelHelpTypeConditionInput
  ) {
    deleteHelpType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createIncentive = /* GraphQL */ `
  mutation CreateIncentive(
    $input: CreateIncentiveInput!
    $condition: ModelIncentiveConditionInput
  ) {
    createIncentive(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateIncentive = /* GraphQL */ `
  mutation UpdateIncentive(
    $input: UpdateIncentiveInput!
    $condition: ModelIncentiveConditionInput
  ) {
    updateIncentive(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncentive = /* GraphQL */ `
  mutation DeleteIncentive(
    $input: DeleteIncentiveInput!
    $condition: ModelIncentiveConditionInput
  ) {
    deleteIncentive(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createSpecialService = /* GraphQL */ `
  mutation CreateSpecialService(
    $input: CreateSpecialServiceInput!
    $condition: ModelSpecialServiceConditionInput
  ) {
    createSpecialService(input: $input, condition: $condition) {
      id
      clientID
      date
      serviceName
      typeID
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSpecialService = /* GraphQL */ `
  mutation UpdateSpecialService(
    $input: UpdateSpecialServiceInput!
    $condition: ModelSpecialServiceConditionInput
  ) {
    updateSpecialService(input: $input, condition: $condition) {
      id
      clientID
      date
      serviceName
      typeID
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpecialService = /* GraphQL */ `
  mutation DeleteSpecialService(
    $input: DeleteSpecialServiceInput!
    $condition: ModelSpecialServiceConditionInput
  ) {
    deleteSpecialService(input: $input, condition: $condition) {
      id
      clientID
      date
      serviceName
      typeID
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpecialServiceType = /* GraphQL */ `
  mutation CreateSpecialServiceType(
    $input: CreateSpecialServiceTypeInput!
    $condition: ModelSpecialServiceTypeConditionInput
  ) {
    createSpecialServiceType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateSpecialServiceType = /* GraphQL */ `
  mutation UpdateSpecialServiceType(
    $input: UpdateSpecialServiceTypeInput!
    $condition: ModelSpecialServiceTypeConditionInput
  ) {
    updateSpecialServiceType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpecialServiceType = /* GraphQL */ `
  mutation DeleteSpecialServiceType(
    $input: DeleteSpecialServiceTypeInput!
    $condition: ModelSpecialServiceTypeConditionInput
  ) {
    deleteSpecialServiceType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createLayette = /* GraphQL */ `
  mutation CreateLayette(
    $input: CreateLayetteInput!
    $condition: ModelLayetteConditionInput
  ) {
    createLayette(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateLayette = /* GraphQL */ `
  mutation UpdateLayette(
    $input: UpdateLayetteInput!
    $condition: ModelLayetteConditionInput
  ) {
    updateLayette(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteLayette = /* GraphQL */ `
  mutation DeleteLayette(
    $input: DeleteLayetteInput!
    $condition: ModelLayetteConditionInput
  ) {
    deleteLayette(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const createStoreVisit = /* GraphQL */ `
  mutation CreateStoreVisit(
    $input: CreateStoreVisitInput!
    $condition: ModelStoreVisitConditionInput
  ) {
    createStoreVisit(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateStoreVisit = /* GraphQL */ `
  mutation UpdateStoreVisit(
    $input: UpdateStoreVisitInput!
    $condition: ModelStoreVisitConditionInput
  ) {
    updateStoreVisit(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteStoreVisit = /* GraphQL */ `
  mutation DeleteStoreVisit(
    $input: DeleteStoreVisitInput!
    $condition: ModelStoreVisitConditionInput
  ) {
    deleteStoreVisit(input: $input, condition: $condition) {
      id
      clientID
      date
      createdAt
      updatedAt
    }
  }
`;
