import styled from 'styled-components';

export const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
export const ColumnHeader = styled(Flex)`
	h2 {
		white-space: nowrap;
	}
	button {
		display: flex;
		align-items: center;
		color: var(--primary-tint);
		font-weight: bold;
		margin-left: 1rem;
		font-size: 1rem;
		svg {
			margin-right: 0.25rem;
		}
		span {
			display: block;
			line-height: 1;
			padding-top: 0.125rem;
		}
	}
`;

export const DashboardContainer = styled.main`
	display: grid;
	width: 100vw;
	height: 100vh;
	grid-template-columns: 250px 1fr;
	grid-template-areas:
		'menu-bar top-bar'
		'menu-bar content';
`;
export const SearchContainer = styled.aside`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 0;
`;
export const TopContainer = styled.aside`
	grid-area: 'top-bar';
	background-color: #18202c;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0.75rem 1rem;
`;

export const NavContainer = styled.nav`
	display: flex;
	flex-direction: column;
	grid-area: menu-bar;
	justify-content: space-between;
	width: 250px;
	height: 100vh;
	background-color: var(--dark-blue);
	ul {
		display: flex;
		flex-direction: column;
		height: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
	}
`;

export const NavTitle = styled.div`
	background-color: #18202c;
	padding: 1rem 1.5rem;
	margin-bottom: 1.5rem;
	h1 {
		color: #fff;
		font-size: 1.625rem;
	}
`;

export const MenuButton = styled.li`
	display: ${(props) => (props.show ? 'block' : 'none')};

	cursor: pointer;
	&:hover {
		transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		background-color: #232f3e;
	}
	a {
		color: rgba(255, 255, 255, 0.7);
		font-size: 0.875rem;
		font-weight: 500;
		display: flex;
		align-items: center;
		padding: 1rem 2rem;
		text-decoration: none;
		svg {
			margin-right: 0.5rem;
		}
	}
`;

export const SignOutButton = styled.button`
	margin-top: auto;
	width: 100%;
	padding: 1rem 2rem;
	color: #ffffff;
	font-size: 1rem;
	font-weight: 700;
	background-color: var(--primary-tint);
	transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	svg {
		margin-left: 0.5rem;
	}
	&:hover {
		background-color: var(--primary-shade);
	}
`;

export const Button = styled.button`
	padding: 0.75rem 1rem;
	color: #ffffff;
	border-radius: 0.25rem;
	font-weight: 500;
	background-color: var(--primary-tint);
	transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	svg {
		margin-right: 0.5rem;
	}
	span {
		margin-left: 0.5rem;
		svg {
			margin-right: 0;
			margin-left: 0.5rem;
		}
	}
	&:hover {
		background-color: var(--primary-shade);
	}
`;
export const ActivityDeleteButton = styled.button`
	margin-left: auto;
	font-size: 1rem;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	a {
		color: var(--black);
	}
`;

export const ContentContainer = styled.main`
	position: relative;
	width: 100%;
	height: calc(100vh - 60px);
	grid-area: content;
	padding: 2rem;
	background-color: #eaeff1;
	overflow-y: scroll;
`;

export const DashboardTitle = styled.header`
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	margin-bottom: 2rem;
	h1 {
		margin-right: 5rem;
		color: #18202c;
	}
	p {
		width: 100%;
	}
	button {
		color: #1976d2;
	}
	a {
		text-decoration: none;
	}
	.dashboard-buttons {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 50%;
		button {
			width: max-content;
			margin: 0 1rem;
		}
	}
`;

export const Loading = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--primary-color);
`;
export const ReportSection = styled.section``;
export const ReportContainer = styled.div`
	display: flex;
	justify-content: flex-start;
`;
export const ReportYear = styled.div`
	width: 25%;
`;
export const ReportTable = styled.div`
	width: 50%;
`;
export const ItemList = styled.section`
	width: 100%;
	border-radius: 0.25rem;
	overflow: hidden;
	/* padding: 2rem 0; */
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	.item {
		font-size: 0.875rem;
		width: 20%;
		min-width: max-content;
		&.description {
			min-width: auto;
			width: 60%;
		}
	}
`;

export const ItemListTitles = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem 1.5rem;
	border-bottom: 1px solid #000;
	color: #fff;
	font-weight: 700;
	font-size: 1rem;
	background-color: var(--primary-tint);
	div:last-child {
		margin-left: auto;
		text-align: right;
	}
`;
export const ItemRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
	/* margin: 0 1rem; */
	background-color: #f7f7f7;
	border-bottom: 1px solid #bbb;
	.action {
		display: flex;
		margin-left: auto;
		svg {
			font-size: 1rem;
		}
		.save-button {
			text-decoration: underline;
			color: var(--primary-color);
			font-weight: 500;
		}
		.toggle-button {
			color: var(--warning);
			svg {
				color: #000000;
			}
		}
		.delete-button {
			margin-left: 1rem;
		}
	}
`;

export const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
`;

export const FormField = styled.div``;

export const FormGroup = styled.aside`
	p {
		font-size: 1rem;
		font-weight: 500;
		margin-left: 2rem;
	}
`;

export const ProfilePhoto = styled.div`
	margin-right: 2rem;
	position: relative;
	.photo {
		height: 100px;
		width: 100px;
		border-radius: 50px;
		background-color: #fff;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 100px;
			object-fit: cover;
		}
		svg {
			font-size: 3rem;
		}
	}
	.edit-button {
		position: absolute;
		bottom: 0.125rem;
		right: 0.125rem;
		background-color: var(--primary-tint);
		padding: 0.75rem;
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			font-size: 0.75rem;
			color: #fff;
			margin: 0;
		}
	}
`;

export const DetailItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 1.5rem;
	svg {
		width: 1.25rem;
		margin-left: 1rem;
		&:first-of-type {
			margin-left: 0;
		}
		&:not(.MuiSvgIcon-root) {
			margin-right: 0.75rem;
		}
	}
`;

export const DisplayItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #ffffff;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	padding: 1rem 0.75rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	svg {
		width: 1.25rem;
		margin-right: 0.75rem;
		margin-left: 1rem;
		&:first-of-type {
			margin-left: 0;
		}
	}
	span {
		font-size: 0.875rem;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.75);
	}
	p {
		margin: 0.25rem 0;
	}
	&:hover {
		button {
			transition: 0.15s all ease-in-out;
			pointer-events: initial;
			visibility: visible;
			opacity: 1;
		}
	}
`;

export const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: ${(props) => (props.active ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	p,
	select {
		margin-right: 0.25rem;
	}
`;

export const Modal = styled.div`
	max-width: 500px;
	padding: 3rem;
	background-color: #fff;
	border-radius: 0.25rem;
	button.warning {
		background-color: var(--warning);
		color: #fff;
		font-weight: bold;
		padding: 0.5rem 1rem;
		border-radius: 0.25rem;
		margin-right: 1rem;
		transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		&:hover {
			background-color: var(--warning-shade);
		}
	}
`;

export const Form = styled.form`
	max-width: 650px;
	.MuiInputBase-input {
	}
	div {
		display: flex;
		width: 100%;
		/* margin: 1rem 0; */
		/* position: relative; */
	}
	label {
		/* display: block;
		font-size: 0.875rem;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.5);
		letter-spacing: 0.2px;
		padding: 0.375rem 0.25rem;
		position: absolute;
		top: 50%;
		left: 0.75rem;
		transform: translateY(-50%); */
	}
	input[type='text'],
	input[type='email'] {
		/* letter-spacing: 0.2px;
		padding: 12px 16px 14px;
		width: 100%;
		max-width: 500px;
		appearance: none;
		outline: 0;
		border: 1px solid #d7d7d7;
		background-color: #fff;
		border-color: #ccc;
		border-radius: 4px;
		transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
		&:hover {
			border-color: rgba(0, 0, 0, 0.87);
		}
		&:focus {
			border: 2px solid var(--primary-color);
		} */
	}
	button {
		color: #ffffff;
		span {
			display: flex;
			align-items: center;
			white-space: nowrap;
			margin-left: 0;
		}
		svg {
			margin-right: 0.25rem;
		}
	}
`;

export const ClientSuggestions = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
	margin-top: -1.5rem;
	padding-left: 2rem;
	a {
		font-size: 0.75rem;
		color: var(--dark-blue);
		padding: 0.5rem;
		background: white;
		margin: 0;
		margin-bottom: 0.125rem;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		border-radius: 0.25rem;
		text-decoration: none;
		&:hover {
			transition: 0.2s all ease;
			transform: translateX(-2px);
		}
	}
	p {
		background: white;
		margin: 0;
		margin-bottom: 0.125rem;
		color: var(--primary-tint);
		padding: 0.75rem;
		font-size: 0.875rem;
		font-weight: 600;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		border-radius: 0.25rem;
	}
`;
export const DashboardTabs = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	/* padding-bottom: 0.25rem; */
	border-bottom: 1px solid var(--primary-tint);
	margin-bottom: 1rem;
	button {
		/* margin-right: 1rem; */
		font-size: 1.25rem;
		font-weight: bold;
		padding: 0.5rem 1rem;
		background: #ffffff;
		border-right: 1px solid var(--primary-tint);
		span {
			font-size: 1rem;
			display: inline-block;
			padding-left: 0.5rem;
		}
	}
	${(props) => {
		if (props.active === 'client') {
			return `
				button:nth-of-type(1) {
					color: #ffffff;
					background: var(--primary-tint);
				}
			`;
		} else if (props.active === 'activities') {
			return `
				button:nth-of-type(2) {
					color: #ffffff;
					background: var(--primary-tint);
				}
			`;
		} else if (props.active === 'notes') {
			return `
				button:nth-of-type(3) {
					color: #ffffff;
					background: var(--primary-tint);
				}
			`;
		}
	}}
`;
export const NotesDashboard = styled.div`
	div {
		width: 100%;
		max-width: 650px;
	}
`;
export const ActivitiesDashboard = styled.div`
	display: flex;
	section {
		width: 70%;
		max-width: 400px;
	}
`;
export const ActivityMenu = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0 1rem;
	width: 30%;
`;
export const ActivityMenuItem = styled.li`
	padding: 1rem;
	cursor: pointer;
	background: ${(props) => (props.active ? 'var(--primary-tint)' : 'white')};
	color: ${(props) => (props.active ? 'white' : 'var(--dark-blue)')};
	transition: 0.1s all ease-in-out;
	&:hover {
		background: ${(props) =>
			props.active ? `var(--primary-tint)` : `var(--primary-shade)`};
		color: white;
	}
	svg {
		margin-right: 0.5rem;
	}
`;

export const ClientData = styled.div`
	max-width: 1400px;
	> section {
		/* width: 60%; */
		width: 50%;
		margin-right: 2rem;
	}
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	/* flex-wrap: wrap; */
`;
export const ClientAside = styled.aside`
	width: 20%;
	min-width: 400px;
	margin-left: auto;
	/* display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap; */
`;
export const ClientEditHeader = styled.div`
	display: block;
	margin-bottom: 4rem;
`;
export const ClientAddChild = styled.div`
	display: block;
`;
export const ChildColumn = styled.section`
	width: 20%;
	padding-right: 1rem;
`;
export const ChildItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #ffffff;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	padding: 1rem 0.75rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	svg {
		width: 1.25rem;
		margin-right: 0.75rem;
	}
	p {
		margin: 0;
	}
	button {
		margin-left: auto;
	}
`;
export const ClientActionButton = styled.button`
	/* width: 175px;
	height: 175px;
	padding: 2rem;
	border-radius: 1rem;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.75rem;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	transition: 0.3 all ease-in-out;
	&:hover {
		transform: translate(-1px, -2px);
	}
	span {
		margin-top: 1.5rem;
		font-size: 1.25rem;
		font-weight: bold;
	} */

	color: var(--primary-tint);
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	font-size: 1.125rem;
	font-weight: bold;
	padding: 1rem 0;
`;

export const ButtonsColumn = styled.div`
	width: 60%;
	padding-right: 1rem;
`;
export const DashboardSearch = styled.div`
	width: 100%;
	margin: 3rem 0;
`;
export const DashboardColumns = styled.section`
	display: flex;
	justify-content: flex-start;
	> div {
		width: 50%;
	}
`;
export const DashboardButton = styled.button`
	width: 175px;
	height: 175px;
	padding: 2rem;
	border-radius: 1rem;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.75rem;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	transition: 0.3 all ease-in-out;
	&:hover {
		transform: translate(-1px, -2px);
	}
	span {
		margin-top: 1.5rem;
		font-size: 1.25rem;
		font-weight: bold;
	}
	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: var(--dark-blue);
	}
`;

export const ClientForm = styled(Form)``;

export const SearchForm = styled.form`
	display: flex;
	width: 100%;
	justify-content: center;
	border: 1px solid #707070;
	border: ${(props) => props.err && '1px solid #d81919;'};
	background-color: #fff;
	padding: 0 0 0 15px;
	border-radius: 30px;
	${(props) => props.location === 'clients' && 'width: 500px;'}
	input {
		box-sizing: border-box;
		outline: none;
		border: none;
		width: 100%;
		background-color: transparent;
		${(props) => {
			if (props.location === 'home')
				return 'padding: 1rem; font-size: 1.25rem';
			if (props.location === 'clients')
				return 'padding: 0.75rem 1rem; font-size: 1rem;';
		}};
		&::placeholder {
			${(props) => props.err && 'color: #d81919; font-weight:600'};
		}
	}
	button {
		cursor: pointer;
		color: #707070;

		padding: 0.5rem;
		width: 2.5rem;
		svg {
			font-size: 0.875rem;
		}
	}
`;

export const FieldWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	max-width: 800px;

	.half {
		/* width: 50%; */
		padding-right: 1rem;
	}
	.full {
		width: 100%;
		padding-bottom: 0.75rem;
	}
	.third {
		width: calc(100% / 3);
	}
	&.wrap {
		flex-wrap: wrap;
	}
`;
