import {Loading} from '../Global/StyledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';

const Loader = () => {
	return (
		<Loading>
			<FontAwesomeIcon icon={faSpinner} size="4x" pulse />
		</Loading>
	);
};

export default Loader;
