import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as mutations from '../graphql/mutations';

import {toDateString, fromDateString} from '../lib/helpers';
import {Controller} from 'react-hook-form';
import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import {
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	MenuItem,
} from '@material-ui/core';
import DatePicker from '../Components/DatePicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faVenusMars, faSeedling} from '@fortawesome/pro-solid-svg-icons';

const ChildForm = ({client, child, editMode, childForm, setActive}) => {
	// console.log(child, client);
	const childLength = child && Object.keys(child).length > 0;
	const editChild = childLength;
	const {control, handleSubmit, watch} = childForm;

	const pregnancyField = watch('pregnancy');

	async function createChild(newChild) {
		const response = await API.graphql({
			query: mutations.createChild,
			variables: {input: newChild},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			childForm.reset();
			setActive('client');
		}

		return response;
	}
	async function updateChild(existingChild) {
		console.log(existingChild);
		const response = await API.graphql({
			query: mutations.updateChild,
			variables: {input: existingChild},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			childForm.reset();
			setActive('client');
		}

		return response;
	}

	const onSubmit = (data) => {
		// console.log(data);
		let childValues = data;
		childValues.clientID = client;
		if (data.birthyear) {
			childValues.birthdate = toDateString(
				data.birthmonth,
				data.birthday,
				data.birthyear
			);
		}
		if (data.dueyear) {
			childValues.dueDate = toDateString(
				data.duemonth,
				data.dueday,
				data.dueyear
			);
		}
		let parsed = Object.keys(childValues)
			.filter((k) => childValues[k] != null)
			.reduce((a, k) => ({...a, [k]: childValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;
		delete parsed.duemonth;
		delete parsed.dueday;
		delete parsed.dueyear;
		delete parsed.birthmonth;
		delete parsed.birthday;
		delete parsed.birthyear;

		console.log(parsed);
		if (!editMode) {
			createChild(parsed);
		} else {
			updateChild(parsed);
		}
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<DetailItem>
				<FieldWrapper>
					<FormControlLabel
						label="New Pregnancy?"
						control={
							<Controller
								name="pregnancy"
								control={control}
								defaultValue={
									editChild ? child.pregnancy : true
								}
								render={({field}) => (
									<Checkbox
										checked={field.value}
										{...field}
									/>
								)}
							/>
						}
					/>
				</FieldWrapper>
			</DetailItem>
			<section>
				{pregnancyField && (
					<DetailItem>
						<FieldWrapper>
							<DatePicker
								groupLabel="Due Date"
								form={childForm}
								initialValue={
									(child &&
										fromDateString(child.dueDate)) || {
										month: 'January',
									}
								}
								nameObj={{
									month: 'duemonth',
									day: 'dueday',
									year: 'dueyear',
								}}
							/>
						</FieldWrapper>
					</DetailItem>
				)}
				<DetailItem>
					<FontAwesomeIcon icon={faVenusMars} />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="gender-field"
							name="gender"
							label="Gender"
							defaultValue={(child && child.gender) || ''}
							variant="outlined">
							<MenuItem value="Boy">Boy</MenuItem>
							<MenuItem value="Girl">Girl</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faSeedling} />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="feedingType-field"
							name="feedingType"
							label="Feeding Type"
							defaultValue={(child && child.feedingType) || ''}
							variant="outlined">
							<MenuItem value="Bottle">Bottle</MenuItem>
							<MenuItem value="Breast">Breast</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
				{!pregnancyField && (
					<>
						<DetailItem>
							<FieldWrapper>
								<FontAwesomeIcon icon={faUser} size="lg" />

								<Controller
									name="firstName"
									control={control}
									render={({field}) => (
										<TextField
											label="First Name"
											variant="outlined"
											type="text"
											defaultValue={
												(child && child.firstName) || ''
											}
											autoComplete="foo"
											{...field}
										/>
									)}
								/>
								<Controller
									// id="outlined-basic"
									// className="half"
									control={control}
									name="lastName"
									render={({field}) => (
										<TextField
											label="Last Name"
											variant="outlined"
											autoComplete="foo"
											defaultValue={
												(child && child.lastName) || ''
											}
											type="text"
											{...field}
										/>
									)}
								/>
							</FieldWrapper>
						</DetailItem>
						<DetailItem>
							<FieldWrapper>
								<DatePicker
									groupLabel="Date of Birth"
									form={childForm}
									initialValue={
										(child &&
											fromDateString(
												child.birthdate
											)) || {
											month: 'January',
										}
									}
									nameObj={{
										month: 'birthmonth',
										day: 'birthday',
										year: 'birthyear',
									}}
								/>
							</FieldWrapper>
						</DetailItem>
					</>
				)}
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default ChildForm;
