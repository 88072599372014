import * as React from 'react';
import {TopContainer} from '../Global/StyledComponents';
import SearchBar from './SearchBar';

const TopBar = ({setClientsSearch}) => {
	return (
		<TopContainer>
			<SearchBar setClientsSearch={setClientsSearch} />
		</TopContainer>
	);
};

export default TopBar;
