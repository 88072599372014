import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {MenuButton} from '../Global/StyledComponents';

const NavButton = ({link, show, label, icon, children}) => {
	return (
		<MenuButton show={show}>
			{link ? (
				<Link to={link}>
					<FontAwesomeIcon icon={icon} />
					{label}
				</Link>
			) : (
				<button type="button">
					<FontAwesomeIcon icon={icon} />
					{label}
				</button>
			)}
		</MenuButton>
	);
};

export default NavButton;
